<template>
  <div class="P-handover">
    <div class="mb-5 P-handover__container P-insurance px-5 py-2">
      <div class="d-flex align-center">
        <span class="mdi mdi-shield-outline icon me-2"></span>
        <span class="P-insurance__title">Insurance</span>
      </div>

      <hr class="my-4" />

      <p class="P-insurance__risk-text mb-2">
        1 of your shipments is <span>at risk of loss or damage</span> and is
        eligible for insurance.
      </p>

      <p class="P-insurance__alert px-5 py-2 d-flex align-center mb-2">
        <span class="mdi mdi-alert-circle-outline icon me-2" /> Some of the
        couriers you selected provide very limited to no compensation in the
        event of loss or damage.
      </p>

      <div>
        <v-radio-group v-model="isPremiumShipping">
          <div class="mb-2">
            <ott-radio :value="true">
              <div class="d-flex align-center" slot="customLabel">
                <p class="mb-0 text-14">
                  <span class="primary-txt"><b>Yes</b></span> - Add only ${{
                    couriersList[0]?.insurance_fee
                  }}
                  for Premium insurance to cover the goods up to $126.00 against
                  damage, loss and theft.
                </p>
              </div>
            </ott-radio>
          </div>

          <ott-radio :value="false">
            <div class="d-flex align-center" slot="customLabel">
              <p class="mb-0 text-14 pt-1">
                <span class="primary-txt"><b>No</b></span> - I am willing to
                risk the goods being lost or damaged, and I understand that
                Easyship is unable to assist in any claims if either were to
                occur.
              </p>
            </div>
          </ott-radio>
        </v-radio-group>
      </div>
    </div>

    <div class="P-handover__container P-addresses px-5 pb-2">
      <div class="d-flex P-addresses__flex-cont">
        <div class="pe-5 pt-2">
          <p class="mb-0 d-flex justify-space-between align-center">
            <b>Ship From</b>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="mdi mdi-alert-circle-outline icon txt-16 G-cursor-pointer"
                >
                </span>
              </template>
              <span
                >Shipping rate is calculated from this address. If you select a
                pickup service, this will be the location from which the courier
                picks up the package.</span
              >
            </v-tooltip>
          </p>

          <p class="text-14 color-neutral my-5">{{ companyAddressName }}</p>
        </div>

        <div class="ps-5 pt-1">
          <p class="mb-0 d-flex justify-space-between align-center">
            <b>Address On Line</b>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="mdi mdi-alert-circle-outline icon txt-16 G-cursor-pointer"
                >
                </span>
              </template>
              <span
                >Alternative address, displayed on the label, that will be seen
                by the receiver</span
              >
            </v-tooltip>
          </p>

          <div class="w-100 my-5 pb-1">
            <ott-select
              v-model="addressOnLabel"
              :items="companyExistingAddresses"
              :top="true"
              :nudgeTop="10"
              label="Address"
              selected-text="name"
            />
          </div>
        </div>
      </div>

      <div class="py-4 d-flex align-center">
        <img
          :src="`https://assets.easyship.com/app/courier-logos/${selectedCourier.courier?.logo_url}-mini.svg`"
          class="courier-logo me-2"
        />

        <span class="text-14">{{ selectedCourier.courier_name }}</span>
      </div>

      <hr class="mb-4" />

      <div>
        <v-radio-group v-model="isStandartPickup">
          <div class="mb-4 w-max-content">
            <ott-radio :value="true">
              <div class="d-flex align-center" slot="customLabel">
                <p class="mb-0 text-14">
                  Schedule a pickup with USPS (Standard Pickup)
                </p>
              </div>
            </ott-radio>
          </div>

          <ott-radio :value="false">
            <div class="d-flex w-max-content align-center" slot="customLabel">
              <p class="mb-0 text-14">
                Drop-off at USPS
                <span class="primary-txt">(See dropoff locations)</span>
              </p>
            </div>
          </ott-radio>
        </v-radio-group>
      </div>

      <transition name="fade">
        <div v-if="isStandartPickup">
          <hr class="mt-4" />

          <div class="d-flex P-addresses__flex-cont border-bottom-0">
            <div class="pe-5 pt-2">
              <p class="mb-0 d-flex justify-space-between align-center">
                <b>Pickup Info</b>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="mdi mdi-alert-circle-outline icon txt-16 G-cursor-pointer"
                    >
                    </span>
                  </template>
                  <span> Hovered text... </span>
                </v-tooltip>
              </p>

              <transition name="fade">
                <p
                  v-if="pickupAddressName"
                  class="text-14 color-neutral mt-5 mb-1"
                >
                  {{ pickupAddressName }}
                </p>
              </transition>

              <transition name="fade">
                <p
                  v-if="pickupDateInfo"
                  class="text-14 color-neutral"
                  v-html="pickupDateInfo"
                />
              </transition>
            </div>

            <div class="ps-5 pt-1">
              <p class="mb-0 d-flex justify-space-between align-center">
                <b>Service Options</b>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="mdi mdi-alert-circle-outline icon txt-16 G-cursor-pointer"
                    >
                    </span>
                  </template>
                  <span>Hovered Text...</span>
                </v-tooltip>
              </p>

              <div class="G-flex-wrap">
                <div class="P-padding-100">
                  <ott-select
                    v-model="pickupAddress"
                    :items="companyExistingAddresses"
                    :top="true"
                    :nudge-top="10"
                    label="Address"
                    selected-text="name"
                  />
                </div>

                <div class="P-padding-50">
                  <div class="G-date-picker mb-0">
                    <DatePicker
                      v-model="pickupDate"
                      :masks="{ input: ottDateFormat }"
                      :min-date="newDateWithMainUserTZ"
                      :popover="{ visibility: 'click', placement: 'top-start' }"
                      :is-dark="$vuetify.theme.dark"
                      locale="en"
                      class="ott-date-picker"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="G-calendar-input">
                          <v-text-field
                            :value="inputValue"
                            v-on="inputEvents"
                            :readonly="true"
                            outlined
                            dense
                            height="38px"
                            label="Pickup Date"
                            class=""
                          />
                          <span
                            class="P-date-icon neutral--text"
                            @click="pickupDate = null"
                            :class="
                              !inputValue
                                ? 'mdi mdi-calendar-blank-outline neutral--text P-calendar'
                                : 'icon-cancel'
                            "
                          ></span>
                        </div>
                      </template>
                    </DatePicker>
                  </div>
                </div>

                <div class="P-padding-50">
                  <ott-select
                    v-model="pickupTime"
                    :items="pickupTimesList"
                    :top="true"
                    :nudgeTop="10"
                    label="Time"
                    selected-text="name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import DatePicker from "v-calendar/src/components/DatePicker";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttSelect from "@/components/vuetifyComponents/OttSelect";

export default {
  name: "HandoverStep",
  mixins: [DateFormatMixin, ConvertTimeZoneMixin],
  components: {
    DatePicker,
    OttRadio,
    OttSelect,
  },
  computed: {
    ...mapState({
      premiumShipping: (state) => state.shippingClients.isPremiumShipping,
      ottProviderId: (state) => state.auth.user.provider.id,
      companyExistingAddresses: (state) =>
        state.companyAddressesAdmin.shippingAddresses,
      shippingAddressOnLabel: (state) => state.shippingClients.addressOnLabel,
      shippingPickupAddress: (state) => state.shippingClients.pickupAddress,
      shippingPickupDate: (state) => state.shippingClients.pickupDate,
      shippingPickupTime: (state) => state.shippingClients.pickupTime,
      couriersList: (state) => state.shippingClients.couriersList,
      selectedCourierId: (state) => state.shippingClients.selectedCourier,
      shipFromAddressId: (state) => state.shippingClients.shipFromAddressId,
      standartPickup: (state) => state.shippingClients.isStandartPickup,
    }),

    isPremiumShipping: {
      set(isPremiumShipping) {
        this.setData({ isPremiumShipping });
      },
      get() {
        return this.premiumShipping;
      },
    },

    addressOnLabel: {
      set(addressOnLabel) {
        this.setData({ addressOnLabel });
      },
      get() {
        return this.shippingAddressOnLabel;
      },
    },

    pickupAddress: {
      set(pickupAddress) {
        this.setData({ pickupAddress });
      },
      get() {
        return this.shippingPickupAddress;
      },
    },

    pickupDate: {
      set(pickupDate) {
        this.setData({ pickupDate });
      },
      get() {
        return this.shippingPickupDate;
      },
    },

    pickupTime: {
      set(pickupTime) {
        this.setData({ pickupTime });
      },
      get() {
        return this.shippingPickupTime;
      },
    },

    isStandartPickup: {
      set(isStandartPickup) {
        this.setData({ isStandartPickup });
      },
      get() {
        return this.standartPickup;
      },
    },

    companyAddressName() {
      let addressName = '';
      this.companyExistingAddresses.map((item) => {
        if (item.value === this.addressOnLabel) {
          addressName = item.name;
        }
      });
      return addressName;
    },

    pickupAddressName() {
      let addressName = null;
      this.companyExistingAddresses.map((item) => {
        if (item.value === this.pickupAddress) {
          addressName = item.name;
        }
      });
      return addressName;
    },

    pickupDateInfo() {
      let dateInfo = "";
      if (this.pickupDate) {
        dateInfo += `${this.$moment(this.pickupDate).format(
          this.ottDateFormat
        )} `;

        if (this.pickupTime) {
          dateInfo += `${this.pickupTime?.start} ~ ${this.pickupTime?.end}`;
        }
      }
      return dateInfo;
    },

    selectedCourier() {
      let courier = {};
      this.couriersList.map((item) => {
        if (item.courier_id === this.selectedCourierId) {
          courier = item;
        }
      });
      return courier;
    },
  },
  watch: {
    shipFromAddressId(newVal) {
      if (newVal) {
        this.addressOnLabel = this.shipFromAddressId;
        console.log( this.addressOnLabel, ' this.addressOnLabel')
      }
    }
  },
  data() {
    return {
      pickupTimesList: [],
    };
  },
  async created() {
    await this.getCompanyAddresses(this.ottProviderId);
    this.pickupTimesList = this.generatePickupTimes();
  },
  methods: {
    ...mapActions({
      getCompanyAddresses: "companyAddressesAdmin/getCompanyAddresses",
    }),

    ...mapMutations({
      setData: "shippingClients/setData",
    }),

    generatePickupTimes() {
      let schedule = [];

      for (let i = 8; i <= 23; i++) {
        let j = i;
        if (i < 10) {
          i = "0" + i;
        }
        let start = i + ":00";
        let end =
          (j + 12) % 24 < 10
            ? "0" + ((j + 12) % 24) + ":00"
            : ((j + 12) % 24) + ":00";
        let timeRange = {
          name: `${start} - ${end}`,
          value: {
            start: start,
            end: end,
          },
        };

        schedule.push(timeRange);

        // Add another time range for the second half of the hour
        start = i + ":30";
        end =
          (j + 12) % 24 < 10
            ? "0" + ((j + 12) % 24) + ":30"
            : ((j + 12) % 24) + ":30";
        timeRange = {
          name: `${start} - ${end}`,
          value: {
            start: start,
            end: end,
          },
        };

        schedule.push(timeRange);
      }

      return schedule;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-handover {
  color: #164367;
  &__container {
    min-height: 265px;
    height: auto;
    background: white;
    box-shadow: 0px 0px 5px #00000012;
    border-radius: 4px;
  }
}

.P-insurance {
  &__title {
    font-weight: 600;
  }

  &__risk-text {
    font-size: $txt14;
    & span {
      color: $danger-color-dark;
    }
  }

  &__alert {
    border-radius: 4px;
    background: rgba(244, 245, 248, 0.62);
  }
}

.P-addresses {
  &__flex-cont {
    border-bottom: 1px solid rgba(165, 170, 198, 0.45);

    & > div {
      width: 50%;
    }

    & > div:nth-child(1) {
      border-right: 1px solid rgba(165, 170, 198, 0.45);
    }
  }
}

hr {
  border: unset;
  border-top: 1px solid rgba(165, 170, 198, 0.45);
}

.icon {
  font-size: $txt20;
  color: $neutral-color;
}

.primary-txt {
  color: $primary-color;
}

.text-14 {
  font-size: $txt14;
}

.text-16 {
  font-size: $txt16;
}

.color-neutral {
  color: $neutral-color;
}

.w-100 {
  width: 100%;
}

.courier-logo {
  width: 40px;
  border-radius: 5px;
}

.border-bottom-0 {
  border-bottom: unset;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>

<style lang="scss">
@import "src/assets/scss/variables";

.P-handover {
  .v-text-field__details {
    display: none !important;
  }
}
</style>