<template>
  <div class="P-order-summary">
    <title-component title-text="Order summary" />

    <ul class="P-order-list">
      <template v-if="invoiceData.generateDisplayInfo.locationsInfo !== undefined">
        <template
          v-for="(location, index) of invoiceData.generateDisplayInfo.locationsInfo.locations"
        >
          <li
            v-if="location.totalPrice !== 0"
            :key="index"
            :class="$vuetify.theme.dark ? 'dark' : 'light'"
            class="G-justify-between"
          >
            <span>{{location.locationName}} ({{ location.locationLogin }})</span>
            <span v-if="location.totalPrice !== 0">{{ 
              new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
              }).format(location.totalPrice)
            }}</span>
            <span v-else>$0.00</span>
          </li>
        </template>
      </template>

      <li
        v-if="invoiceData.generateDisplayInfo.equipmentInfo.equipment !== undefined && invoiceData.generateDisplayInfo.equipmentInfo.equipment.totalPrice !== 0"
        :class="$vuetify.theme.dark ? 'dark' : 'light'"
        class="G-justify-between"
      >
        <span>Equipments</span>
        <span>{{ 
          invoiceData.generateDisplayInfo.equipmentInfo.equipment.totalPrice !== 0
            ? new Intl.NumberFormat('en-US', {
                tyle: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
              }).format(invoiceData.generateDisplayInfo.equipmentInfo.equipment.totalPrice)
            : '$0.00'
        }}</span>
      </li>

      <!-- <li v-if=""> -->
      <!-- <li class="G-justify-between">
        <span>Office (12345678)</span>
        <span>{{ checkoutClients.orderSummary.office }}</span>
      </li> -->
      <li 
        v-if="invoiceData.isShipping" 
        class="G-justify-between" 
        :class="$vuetify.theme.dark ? 'dark' : 'light'"
      >
          <span>Shipping Insurance Fee</span>
          
          <span v-if="invoiceData.payloadCalculated.shippingData?.insuranceFee !== 0">{{ 
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(invoiceData.payloadCalculated.shippingData?.insuranceFee)
          }}</span>
          <span v-else>$0.00</span>
      </li>
      <li 
        v-if="invoiceData.isShipping"
        :class="$vuetify.theme.dark ? 'dark' : 'light'"
        class="G-justify-between" 
      >
          <span>Courier Fee</span>
          <span v-if="invoiceData.payloadCalculated.shippingData?.shipmentTotal !== 0">{{
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(invoiceData.payloadCalculated.shippingData?.shipmentTotal)
          }}</span>
          <span v-else>$0.00</span>
      </li>
      <li
        v-if="invoiceData.isShipping"
        :class="$vuetify.theme.dark ? 'dark' : 'light'"
        class="G-justify-between"
      >
        <span>Total Shipping</span>
        <span v-if="invoiceData.payloadCalculated.totalShipping !== 0">{{ 
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(invoiceData.payloadCalculated.totalShipping)
        }}</span>
        <span v-else>$0.00</span>
      </li>
      <li
        :class="$vuetify.theme.dark ? 'dark' : 'light'"
        class="G-justify-between">
        <span>Tax</span>
        <span v-if="invoiceData.payloadCalculated.totalTax !== 0">{{ 
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(invoiceData.payloadCalculated.totalTax)
        }}</span>
        <span v-else>$0.00</span>
      </li>
      <transition name="fade">
        <li
          v-if="activeTab !== 'cash' && activeTab !== 'check'"
          :class="$vuetify.theme.dark ? 'dark' : 'light'"
          class="G-justify-between">
          <span>Bank Fee</span>
          <span v-if="invoiceData.payloadCalculated.bankFee !== 0">{{
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(invoiceData.payloadCalculated.bankFee)
          }}</span>
          <span v-else>$0.00</span>
        </li>
      </transition>
    </ul>
    <div class="P-divider"></div>
    <ul class="P-order-list">
      <li
        :class="$vuetify.theme.dark ? 'dark' : 'light'"
        class="P-subtotal-block G-justify-between">
        <span>Subtotal</span>
        <span v-if="invoiceData.totalAmount !== 0">{{
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(invoiceData.totalAmount)
        }}</span>
        <span v-else>$0.00</span>
      </li>
      <li
        :class="$vuetify.theme.dark ? 'dark' : 'light'"
        class="G-justify-between">
        <span>Payed</span>
        <span v-if="invoiceData.payed !== 0">{{
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(invoiceData.payed)
        }}</span>
        <span v-else>$0.00</span>
      </li>
      <li
        v-if="checkoutClients.payFromBalance > 0"
        :class="$vuetify.theme.dark ? 'dark' : 'light'"
        class="G-justify-between">
        <span>Pay From Balance</span>
        <span>{{ 
          checkoutClients.payFromBalance !== 0 
            ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
              }).format(checkoutClients.payFromBalance)
            : '$0.00'}}</span>
      </li>
    </ul>

    <div class="P-divider"></div>

    <div v-if="client?.provider?.hasValidPaymentGateway" class="G-flex-center flex-column">
      <transition name="slide">
        <div class="mb-3">
          <ott-phone-number
            v-show="sendType.split('/')[0] === 'phone' && !validPhoneNumber"
            v-model="sendPhoneNumber"
            :error="!validPhoneNumber"
            @onInput="onInputPhoneNumber"
          />
        </div>
      </transition>

      <ott-select
        v-model="sendType"
        :items="invoiceSendTypes"
        @emitFunc="sendTypeSelect"
        selected-text="name"
        selected-value="value"
        label="Invoice Send Type"
      />

      <transition name="fade">
        <ott-button-loader
          v-if="sendType"
          @onClick="sendInvoice"
          button-text="Send Invoice"
          class-name="primary-sm-btn mt-3"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TitleComponent from "@/components/TitleComponent";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "OrderSummary",
  components: { TitleComponent, OttSelect, OttPhoneNumber, OttButtonLoader },
  data() {
    return {
      sendType: '',
      invoiceSendType: {
        type: null,
        value: null
      },
      invoiceSendTypes: [],
      emailsList: [],
      phonesList: [],
      sendPhoneNumber: '',
      validPhoneNumber: true,
      isExistSmtp: false,
      isExistTwilio: false
    }
  },
  computed: {
    ...mapState({
      checkoutClients: (state) => state.checkoutClients,
      clientId: (state) => state.clientsModule.clientId,
      client: (state) => state.clientsModule.client,
      activeTab: (state) => state.checkoutClients.activePaymentType,
    }),
    invoiceData() {
      return this.checkoutClients.invoiceData;
    },
  },
  async mounted() {
    await this.getSendInfos()
    const providerData = await this.getProvider(this.invoiceData?.provider)
    if (providerData) {
      if ('hasValidTwilio' in providerData) {
        this.isExistTwilio = providerData.hasValidTwilio
      }

      if ('hasValidSmtp' in providerData) {
        this.isExistSmtp = providerData.hasValidSmtp
      }

      if (this.isExistSmtp && this.isExistTwilio) {
        this.invoiceSendTypes = [
          {
            name: 'Email & Phone',
            value: 'email_phone'
          }
        ];
      }

      if (this.emailsList.length && this.isExistSmtp) {
        //Setting selected client's emails
        this.emailsList.map(item => {
          this.invoiceSendTypes.push({
            name: `Email ${item.email}`,
            value: `email/${item.email}`
          })
        })
      }

      if (this.phonesList.length && this.isExistTwilio) {
        //Setting selected client's phones
        this.phonesList.map(item => {
          this.invoiceSendTypes.push({
            name: `Phone ${item.phone}`,
            value: `phone/${item.phone}`
          })
        })
      }
    }
  },
  methods: {
    ...mapActions({
      getClientData: "clientsModule/getClientData",
      sendPayment: "checkoutClients/sendPayment",
      getProvider: "addProviderModal/getProviderWitfoutMutation",
      addClientPhone: "contactClient/addContactPhone",
    }),
    sendInvoice() {
      this.sendPayment({data: this.invoiceSendType, id: this.invoiceData.id })
    },
    async onInputPhoneNumber(phone, obj) {
      if (this.sendPhoneNumber) {
        this.validPhoneNumber = obj.isValid;  
      }

      if (this.invoiceSendType.type === 'phone' && this.validPhoneNumber) {
        await this.editClientPhone()
      }
    },

    async editClientPhone() {
      // Editing phone update in phones list
      if (this.phonesList.length) {
        this.phonesList.forEach((item) => {
          if (item?.phone === this.sendType.split('/')[1]) {
            item.phone = this.sendPhoneNumber

            delete item?.updatedAt
            delete item?.createdAt
            delete item?.id
            delete item?.value
          }
        });
      }

      // update editing phone in the invoiceSendTypes
      this.invoiceSendTypes.forEach(item => {
        if (item.value.split('/')[1] === this.invoiceSendType.value) {
          item.name = `Phone ${this.sendPhoneNumber}`
          item.value = `phone/${this.sendPhoneNumber}`
        }
      })
      await this.addClientPhone({ phones: this.phonesList, clientId: this.clientId }).finally(() => this.showWarningModal = false)
  },

    async sendTypeSelect() {
      if (this.sendType === 'email_phone') {
        this.invoiceSendType.type = 'email_phone'
        this.invoiceSendType.value = ''
      }

      if (this.sendType !== 'email_phone') {
        this.invoiceSendType.type = this.sendType.split('/')[0]
        this.invoiceSendType.value = this.sendType.split('/')[1]

        if (this.invoiceSendType.type === 'phone') {
          this.sendPhoneNumber = this.sendType.split('/')[1]
        }
      }
    },

    async getSendInfos() {
      const clientData = await this.getClientData(this.clientId) ?? {}
      this.emailsList = clientData?.emails ?? []
      this.phonesList = clientData?.phones ?? []
      this.emailsList = this.emailsList.filter(item => item.forContactInvoice)
      this.phonesList = this.phonesList.filter(item => item.forMessages)
    }
  }
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "./orderSummary.scss";
.P-order-summary {
  .P-modal-component-header {
    margin-bottom: 0;
  }
  .P-subtotal-block {
    font-weight: $bold;
  }
}
</style>