<template>
  <div class="P-checkout-header">
    <span @click="$emit('close-modal')" class="mdi mdi-close P-close"></span>
    <div class="P-checkout-steps">
      <div class="P-progress-bar">
        <span :style="{ width: getPercent() }" />
      </div>
      <div
        v-for="(step, index) of checkoutSteps"
        class="P-step"
        @click="changeChekoutStep(index + 1)"
        :class="{
          'P-active-index' : checkoutActiveStep === index + 1,
          'P-validated-index' : checkoutActiveStep > index + 1
        }"
        :key="index"
      >
        <span>{{index + 1}}</span>
        <span class="mdi mdi-check P-check-step" />
        <p>{{step}}</p>
      </div>
    </div>
    <div v-if="checkoutActiveStep === 1 && showShippingAccess" class="w-max-content mx-auto mt-5">
      <ott-switch
        v-model="accessShipping"
        :disable="checkoutActiveStep > 1"
        label="Access Shipping"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";

export default {
  name: "CheckoutHeader",
  components: {
    OttSwitch
  },
  computed: {
    ...mapState({
      checkoutActiveStep: (state) => state.checkoutClients.activeStep,
      invoiceData: (state) => state.checkoutClients.invoiceData,
      providerShipData: (state) => state.shipmentProviders.easyShipData,
      accessShippingSwitch: (state) => state.checkoutClients.accessShipping,
      clientEquipments: (state) => state.equipments.clientEquipments,
      clientProviderId: (state) => state.clientsModule.clientProviderId
    }),

    equipmentsList() {
      let equipments = [];
      if (this.clientEquipments.length) {
        equipments = this.clientEquipments.filter((item) => item.equipment && !item.id);
      }

      return equipments;
    },


    showShippingAccess() {
      if (
        this.providerShipData.productionToken &&
        this.equipmentsList.length
      ) {
        return true
      } else {
        return false
      }
    },
    
    checkoutSteps() {
      if (!this.showShippingAccess) {
        return this.steps.filter((item) => item !== "Delivery");
      }

      if (!this.accessShipping && !this.invoiceData.isShipping) {
        return this.steps.filter((item) => item !== "Delivery");
      }

      return this.steps;
    },

    accessShipping: {
      get() {
        return this.accessShippingSwitch
      },
      set(value) {
        this.setData({ accessShipping: !value ? false : value })
      }
    }
  },

  methods: {
    ...mapMutations({
      setData: 'checkoutClients/setData',
      shippingProvidersfetchData: 'shipmentProviders/fetchData',
    }),

    ...mapActions({
      getShippingProviders: "shipmentProviders/getShippingProviders"
    }),

    changeChekoutStep(step) {
      if (this.checkoutActiveStep > step) {
        this.setData({
          activeStep: step
        })
      }
    },

    getPercent() {
      let percent = 0;
      switch (this.checkoutActiveStep) {
        case 1: {
          percent = 0;
          break;
        }
        case 2: {
          percent = 100;
          break;
        }
      }
      return percent + "%";
    },
  },

  async created() {
    if (this.clientProviderId) {
      //get Shipping keys data, for show shipping access switch
      await this.getShippingProviders(this.clientProviderId)
        .then((data) => {
          this.shippingProvidersfetchData(data);
        });
    }
  },

  data() {
    return {
      steps: ['Delivery', 'Payment', 'Confirm']
    }
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.P-checkout-header {
  position: relative;
  padding: 15px;
}

.P-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: $neutral-color;
  font-size: $txt23;
  width: 15px;
  height: 15px;
  cursor: pointer;

  &:before {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.P-checkout-steps {
  max-width: 410px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.P-step {
  position: relative;
  z-index: 10;

  span {
    width: 50px;
    height: 50px;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $neutral-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $neutral-color;
    font-weight: 600;
    font-size: $txt23;
    margin: 0 auto;
  }

  p {
    margin: 0;
    margin-top: 5px;
    color: $neutral-color;
    font-weight: 600;
    font-size: $txt16;
  }

  &.P-active-index {
    span {
      color: $primary-color;
      border-color: $primary-color;
    }

    p {
      color: $primary-color;
    }
  }

  &.P-validated-index {
    cursor: pointer;
    .P-check-step {
      display: flex !important;
    }

    span {
      background-color: $primary-color;
      display: none;
    }

    p {
      color: $neutral-color;
    }
  }
}

.P-progress-bar {
  position: absolute;
  width: 90%;
  height: 1px;
  background-color: $neutral-color45;
  top: 25px;
  left: 15px;

  span {
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $primary-color;
    transition: 0.3s;
  }
}

.P-check-step {
  display: none !important;
  color: $white !important;
  font-size: 40px;
}
</style>

<style lang="scss">
  .v-messages {
    display: none !important;
  }
</style>