<template>
  <div class="order-details">
    <title-component title-text="Order Details" />
    <div class="ps-5 P-forms-cont p-relative P-dashed-border">
      <!-- Ship From Circel -->
      <div
        class="
          P-circle P-colored-circle P-top-circle
          d-flex
          align-center
          justify-content-center
        "
      />
      <!---------------------->

      <div>
        <div class="P-ship-from d-flex align-center mb-2">
          <p class="text-wrap mt-37">{{ shipFromAddress }}</p>
        </div>
      </div>

      <div>
        <div class="P-ship-to d-flex p-relative align-center mt-5">
          <!-- Ship To Circel -->
          <div
            class="
              P-colored-circle P-circle P-bottom-circle
              d-flex
              align-center
              justify-content-center
            "
          />
          <!-------------------->

          <p class="text-wrap mb-0 mt-75">{{ shipToAddress }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TitleComponent from "@/components/TitleComponent";

export default {
  name: "OrderDetails",
  components: {
    TitleComponent
  },
  computed: {
    ...mapState({
      shipFromAddress: (state) => state.shippingClients.shipFromAddress,
      shipToAddress: (state) => state.shippingClients.shipToAddress,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.order-details {
  margin-bottom: 65px;
}

.P-dashed-border {
  border-left: 1px dashed $neutral-color;
}

.P-solid-border {
  border-left: 1px solid $neutral-color;
}

.P-forms-cont {
  height: 70%;
  & .P-ship-from,
  & .P-ship-to {
    height: 16px;
  }
}

.P-top-circle {
  top: 0;
  left: -7.5px;
}

.P-bottom-circle {
  bottom: -24px;
  left: -27.5px;
}

.P-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
}

.P-colored-circle {
  border: 4px solid $primary-color;
  background-color: white;
}

.p-relative {
  position: relative;
}

.mt-37 {
  margin-top: 37px;
}

.mt-75 {
  margin-top: 75px;
}
</style>