import {mapMutations, mapState} from "vuex";

export default {
    computed: {
        ...mapState({
            paymentMethod: state => state.checkoutClients
        }),
        creditTerm: {
            get() {
                return this.paymentMethod.fastCredit.creditTerm
            },
            set(value) {
                this.setCreditTerm(value)
            }
        },
        billSendByEmail: {
            get() {
                return this.paymentMethod.fastCredit.billSendByEmail
            },
            set(value) {
                this.setBillSendByEmail(value)
            }
        },
        billSendByPostalMethod: {
            get() {
                return this.paymentMethod.fastCredit.billSendByPostalMethod
            },
            set(value) {
                this.setBillSendByPostalMethod(value)
            }
        }
    },

    methods: {
        ...mapMutations({
            setCreditTerm: 'checkoutClients/setCreditTerm',
            setBillSendByEmail: 'checkoutClients/setBillSendByEmail',
            setBillSendByPostalMethod: 'checkoutClients/setBillSendByPostalMethod'
        })
    }
}