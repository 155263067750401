import { mapState, mapMutations } from "vuex";

export default {
    data() {
        return {
            headers: ['Name', 'Action', 'Start Date', 'End Date', 'Month/Days', '1M Price', 'Amount']
        }
    },

    computed: {
        ...mapState({
            confirmStep: state => state.checkoutClients,
            generateDisplayInfo: state => state.checkoutClients.invoiceData.generateDisplayInfo
        }),

        showPackages() {
            let show = false;
            
            //if invoceData's locations has buyed package or packages acces to show packages
            if (this.generateDisplayInfo.locationsInfo.locations.length) {
                this.generateDisplayInfo.locationsInfo.locations.map((location) => {
                    if (location.packages.length) {
                        show = true
                    }
                }) 
            }
            return show;
        },

        orderConfirmationData: {
            get() {
                return this.confirmStep.orderReviewData
            }
        },
        invoiceSendByEmail: {
            get() {
                return this.confirmStep.confirm.invoiceSendByEmail
            },
            set(value) {
                this.setInvoiceSendByEmail(value)
            }
        },
        invoiceSendByPostalMethod: {
            get() {
                return this.confirmStep.confirm.invoiceSendByPostalMethod
            },
            set(value) {
                this.setInvoiceSendByPostalMethod(value)
            }
        },
        receiptSendByEmail: {
            get() {
                return this.confirmStep.confirm.receiptSendByEmail
            },
            set(value) {
                this.setReceiptSendByEmail(value)
            }
        }
    },

    methods: {
        ...mapMutations({
            setInvoiceSendByEmail: 'checkoutClients/setInvoiceSendByEmail',
            setInvoiceSendByPostalMethod: 'checkoutClients/setInvoiceSendByPostalMethod',
            setReceiptSendByEmail: 'checkoutClients/setReceiptSendByEmail'
        }),

        getPackageName(nameArray) {
            if (nameArray && nameArray.length) {
              let engName = nameArray.filter(
                (name) => name.lang === "en" || name.lang === "EN"
              );
              let ruName = nameArray.filter(
                (name) => name.lang === "ru" || name.lang === "RU"
              );
              if (engName && engName.length) {
                return engName[0].name;
              } else {
                return ruName[0].name;
              }
            }
        },

        getActionName(action) {
            if (action === 1) {
                return "Subscribe From End Of Max Expire";
            }
            if (action === 2) {
                return "Subscribe To End Of Max Expire";
            }
            if (action === 3) {
                return "Subscribe To Date";
            }
        },
    }
}