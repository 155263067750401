<template>
    <div>
        <title-component title-text="Order Confirmation" />
        <OrderConfirmation />
    </div>
</template>

<script>
    import OrderConfirmation from "./OrderConfirmation";
    import TitleComponent from "@/components/TitleComponent";
    export default {
        name: "ConfirmStep",
        components: {TitleComponent, OrderConfirmation}
    }
</script>

<style scoped>

</style>