var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"P-addresses-step"},[_c('div',{staticClass:"ps-5 mt-4 P-forms-cont p-relative",class:{
      'P-dashed-border': !_vm.isOpenFromForm && !_vm.isOpenToForm,
      'P-solid-border' : _vm.isOpenFromForm || (!_vm.isOpenFromForm && _vm.isOpenToForm)
    }},[_c('div',{staticClass:"P-circle P-top-circle d-flex align-center justify-content-center",class:{
        'P-empty-circle': !_vm.shipFromAddress,
        'P-colored-circle': _vm.shipFromAddress
      }}),_c('div',[_c('div',{staticClass:"P-ship-from d-flex align-center w-max-content mb-2"},[_c('title-component',{attrs:{"click":_vm.openFromForm,"title-text":_vm.shipFromText,"isEdit":_vm.shipFromAddress ? true : false,"isAdd":!_vm.shipFromAddress && !_vm.isOpenFromForm ? true : false}}),(_vm.isOpenFromForm)?_c('div',{staticClass:"icon-cont",on:{"click":function($event){_vm.isOpenFromForm = false}}},[_c('span',{staticClass:"mdi mdi-chevron-up"})]):_vm._e()],1),_c('transition',{attrs:{"name":"slide"}},[(_vm.isOpenFromForm)?_c('ManageAddress',{attrs:{"ship-from":true}}):_vm._e()],1)],1),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"P-ship-to w-max-content d-flex p-relative align-center mt-5"},[_c('div',{staticClass:"P-circle P-bottom-circle d-flex align-center justify-content-center",class:{
            'P-empty-circle': !_vm.shipToAddress,
            'P-colored-circle': _vm.shipToAddress
          }}),_c('title-component',{attrs:{"click":_vm.openToForm,"title-text":_vm.shipToText,"isEdit":_vm.shipToAddress ? true : false,"isAdd":!_vm.shipToAddress && _vm.shipFromAddress && !_vm.isOpenToForm ? true : false}}),(_vm.isOpenToForm)?_c('div',{staticClass:"icon-cont",on:{"click":function($event){_vm.isOpenToForm = false}}},[_c('span',{staticClass:"mdi mdi-chevron-up"})]):_vm._e()],1),_c('transition',{attrs:{"name":"slide"}},[(_vm.isOpenToForm)?_c('ManageAddress',{attrs:{"ship-to":true}}):_vm._e()],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }