import { mapState, mapMutations, mapActions } from "vuex";
import { eventBus } from "@/main";

export default {
    data() {
        return {
            payData: {},
        };
    },
    async created() {
        this.$hubEventBus.$on("package-info", this.handlePackageInfo); // if loggedIn get permissions with WS
    },
    beforeDestoy() {
      this.$hubEventBus.$off("package-info", this.handlePackageInfo); // if loggedIn get permissions with WS
    },
    computed: {
        ...mapState({
            activePaymentType: (state) => state.checkoutClients.activePaymentType,
            checkoutClients: (state) => state.checkoutClients,
            shippingStep: (state) => state.shippingClients.shippingStep,
            shipping: (state) => state.shippingClients.shipping,
            shipFromAddress: (state) => state.shippingClients.shipFromAddress,
            shipToAddress: (state) => state.shippingClients.shipToAddress,
            shipInfoBoxesData: (state) => state.shippingClients.shipInfoBoxesData,
            selectedCourier: (state) => state.shippingClients.selectedCourier,
            isPremiumShipping: (state) => state.shippingClients.isPremiumShipping,
            creditCard: (state) => state.paymentMethodClients.creditCard,
            paymentList: (state) => state.paymentMethodClients.paymentList,
            bankTransfer: (state) => state.paymentMethodClients.bankTransfer,
            cash: (state) => state.paymentMethodClients.cash,
            check: (state) => state.checkoutClients.check,
            moneyOrder: (state) => state.checkoutClients.moneyOrder,
            isStandartPickup: (state) => state.shippingClients.isStandartPickup,
            shippingPickupAddress: (state) => state.shippingClients.pickupAddress,
            shippingPickupDate: (state) => state.shippingClients.pickupDate,
            shippingPickupTime: (state) => state.shippingClients.pickupTime,
            clientId: (state) => state.clientsModule.clientId,
        }),

    },
    async handlePackageInfo(info) {
    },
    methods: {
        ...mapActions({
            checkShipping: "shippingClients/checkShipping",
            getShippingData: "shippingClients/getShippingData",
            changeStep: "checkoutClients/changeStep",
            updatePayData: "checkoutClients/updatePayData",
            addPaymentMethod: 'paymentMethodClients/addPaymentMethod',
        }),
        ...mapMutations({
            setShippingData: "shippingClients/setData",
            setCheckoutData: "checkoutClients/setData",
        }),

        getPayData() {
            if (
                this.checkoutClients.payFromBalance &&
                this.checkoutClients.payFromBalance > 0
            ) {
                this.payData.balance = this.checkoutClients.payFromBalance;
            }

            switch (this.activePaymentType) {
                case 'saved': // Saved Payment Methods
                    this.payData = {}
                    this.payData.paymentMethod = 'saved';
                    if (this.paymentList.length) {
                        this.paymentList.map((item) => {
                            if (item.default) {
                                this.payData.savedCard = item.id;
                            }
                        });
                    }
                    break;

                case 'card': // Credit Card
                    this.payData = {}
                    this.payData.paymentMethod = 'card';
                    this.payData.creditCard = {
                        cardNumber: this.creditCard.cardNumber,
                        cardholderName: this.creditCard.cardholderName,
                        cvc: this.creditCard.cvc,
                        token: this.creditCard.token,
                        month: this.creditCard?.expireDate?.split(" / ")[0],
                        year: this.creditCard?.expireDate?.split(" / ")[1],
                        brand: this.creditCard.brand,
                        anExistingAddress: this.creditCard.anExistingAddress
                            ? this.creditCard.anExistingAddress
                            : false,
                    };

                    if (this.creditCard.anExistingAddress) {
                        this.payData.creditCard.existingAddress =
                            this.creditCard.existingAddress;
                    } else {
                        this.payData.creditCard.billingAddress = {
                            phone: this.creditCard.phone,
                            address: this.creditCard.address,
                            country: this.creditCard.country,
                            city: this.creditCard.city,
                            suite: this.creditCard.suite,
                            zip: this.creditCard.zip,
                            state: this.creditCard.state,
                        };
                    }
                    break;

                case 'bankTransfer': // Bank Account
                    this.payData = {}
                    this.payData.paymentMethod = 'bankTransfer';
                    this.payData.bankTransfer = {
                        bankName: this.bankTransfer.bankName,
                        routingNumber: this.bankTransfer.routingNumber,
                        accountNumber: this.bankTransfer.accountNumber,
                        account: this.bankTransfer.type === "personalAccount" ? 1 : 2,
                    };

                    if (this.bankTransfer.type === "personalAccount") {
                        this.payData.bankTransfer.personalData = {
                            firstname: this.bankTransfer.firstname,
                            lastname: this.bankTransfer.lastname,
                            nickname: this.bankTransfer.nickname,
                        };
                    } else {
                        this.payData.bankTransfer.companyName =
                            this.bankTransfer.companyName;
                    }
                    break;

                case 'check': // Check
                    this.payData = {}
                    this.payData.paymentMethod = 'check';
                    if (!this.checkoutClients.invoiceData?.payloadCalculated?.refund) {
                        this.payData.checkNumber = this.check.number;
                        this.payData.bankName = this.checkoutClients.bankName;
                    }
                    eventBus.$emit("checkoutNext");
                    // this.payData.mailByCheckeeper = this.check.mailByCheckeeper;
                    break;

                case 'cash': // Cash
                    this.payData = {}
                    this.payData.paymentMethod = 'cash';
                    this.payData.cash = {
                        month: this.cash.month,
                    };
                    break;
                case 'moneyOrder': // Money Order
                    this.payData = {}
                    this.payData.paymentMethod = 'moneyOrder';
                    this.payData.moneyOrderNumber = this.moneyOrder.number;
                    this.payData.bankName = this.checkoutClients.bankName;
                    eventBus.$emit("checkoutNext");
                    break;
            }
        },

        async saveCreditCard() {  
            const data = {
                clientId: this.clientId,
                paymentMethod: 0,
                inUse: this.creditCard.inUse,
                default: this.creditCard.default,
                creditCard: {
                    cardNumber: this.creditCard.cardNumber,
                    cardholderName: this.creditCard.cardholderName,
                    month: this.creditCard.month,
                    year: this.creditCard.year,
                    cvc: this.creditCard.cvc,
                    anExistingAddress: this.creditCard.anExistingAddress,
                    brand: this.cardBrand,            
                }
            }
      
            if (this.creditCard.anExistingAddress) {
                data.creditCard.existingAddress = this.creditCard.existingAddress
            } else {
                data.creditCard.billingAddress = {
                    address: this.creditCard.address,
                    country: this.creditCard.country,
                    city: this.creditCard.city,
                    suite: this.creditCard.suite,
                    state: this.creditCard.state,
                    zip: this.creditCard.zip,
                    phone: this.creditCard.phone
                }
            }
            // TODO square  change data according to data in CreditCardMixin.js
      
            await this.addPaymentMethod(data)
        },

        async nextShippingStep() {
            switch (this.shippingStep) {
                case 1: //Addresses Step
                    if (this.shipFromAddress && this.shipToAddress) {
                        this.setShippingData({
                            shippingStep: this.shippingStep + 1,
                        });
                    }
                    break;

                case 2: // Information Step
                    let isValidBoxesForms = false;

                    // check if all boxes are filed or not
                    this.shipInfoBoxesData.map((item) => {
                        if (
                            item.box &&
                            item.width &&
                            item.height &&
                            item.length &&
                            item.weight
                        ) {
                            isValidBoxesForms = true;
                        } else {
                            isValidBoxesForms = false;
                        }
                    });

                    // create and get rates
                    // if shipping information is valid
                    const shippingData = await this.getShippingData({
                        clientId: this.checkoutClients.invoiceData.client,
                        equipments: this.checkoutClients.invoiceData.payloadCalculated.equipment.equipmentInfos,
                        isCheck: true,
                    })
                    if (isValidBoxesForms && await this.checkShipping(shippingData)) {
                        this.setShippingData({
                            shippingStep: 3,
                        });
                    } else {
                        if (!isValidBoxesForms) {
                            this.setShippingData({
                                showShipBoxErrors: true,
                            });
                        }
                    }
                    break;

                case 3: // Courier Step
                    if (this.selectedCourier) {
                        this.setShippingData({
                            shippingStep: this.shippingStep + 1,
                        });
                    }
                    break;

                case 4: // Handover Step
                    if (
                        this.isPremiumShipping !== null
                    ) {
                            this.getPayData();
                            const shippingData = await this.getShippingData({
                                clientId: this.checkoutClients.invoiceData.client,
                                equipments: this.checkoutClients.invoiceData.payloadCalculated.equipment.equipmentInfos,
                                isCheck: false,
                            })

                            shippingData.isPremiumShipping = this.isPremiumShipping;
                            
                            // Add Pickup Address
                            if (this.shippingPickupAddress)
                                shippingData.pickupAddress = this.shippingPickupAddress
    
                            if (this.isStandartPickup) {

                            // Get Pickup start, end dates with times
                            if (this.shippingPickupDate && this.shippingPickupTime) {
                                let startHour = this.shippingPickupTime.start.split(':')[0],
                                    startMinute = this.shippingPickupTime.start.split(':')[1],
                                    endHour = this.shippingPickupTime.end.split(':')[0],
                                    endMinute = this.shippingPickupTime.end.split(':')[1];
    
                                if (startHour === '00') {
                                    startHour = 0
                                } else if (startHour[0] === '0' && startHour !== '00') {
                                    startHour = +startHour.substring(1)
                                }
    
                                if (startMinute === '00') {
                                    startMinute = 0
                                } else if (startMinute[0] === '0' && startMinute !== '00') {
                                    startMinute = +startMinute.substring(1)
                                }
    
                                if (endHour === '00') {
                                    endHour = 0
                                } else if (endHour[0] === '0' && endHour !== '00') {
                                    endHour = +endHour.substring(1)
                                }
    
                                if (endMinute === '00') {
                                    endMinute = 0
                                } else if (endMinute[0] === '0' && endMinute !== '00') {
                                    endMinute = +endMinute.substring(1)
                                }
                                
                                let shippingPickupStart = this.$moment(this.shippingPickupDate).set({ hour: startHour, minute: startMinute });
                                let shippingPickupEnd = this.$moment(this.shippingPickupDate).set({ hour: endHour, minute: endMinute });
                                
                                shippingData.pickupStartDate = shippingPickupStart
                                shippingData.pickupEndDate = shippingPickupEnd
                                }

                            }
    
                            const shipping = await this.checkShipping(shippingData)
                            if (shipping) {
                                this.updatePayData({ shipping: shippingData}).then((data) => {
                                    this.setCheckoutData({ accessShipping: false, showShippingAccess: true, activeStep: 2, hasError: false });
                                }).catch((error) => {
                                    this.setCheckoutData({ hasError: true, errorMessage: error.message });
                                    console.error('error creating shipping.')
                                })
                            }
                    }
                    break;
            }
        },
    }
}