var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"P-table-list"},[_c('div',[(!_vm.hasError)?_c('div',[(_vm.couriersList.length)?_c('table',[_vm._m(0),_vm._l((_vm.couriersList),function(item,index){return [_c('tr',{key:index,staticClass:"P-hovered-tr"},[_c('td',{staticClass:"secondary--text"},[_c('v-radio-group',{model:{value:(_vm.courier),callback:function ($$v) {_vm.courier=$$v},expression:"courier"}},[_c('ott-radio',{attrs:{"value":item.courier_id}},[_c('div',{staticClass:"d-flex align-center",attrs:{"slot":"customLabel"},slot:"customLabel"},[_c('img',{staticClass:"courier-logo ms-1 me-3",attrs:{"src":`https://assets.easyship.com/app/courier-logos/${item.courier?.logo_url}-mini.svg`}}),_c('span',[_vm._v(_vm._s(item.courier_name))])])])],1)],1),_c('td',{staticClass:"secondary--text"},[_c('p',[_vm._v(_vm._s(item.min_delivery_time)+" "),(item.max_delivery_time)?_c('span',[_vm._v("- "+_vm._s(item.max_delivery_time))]):_vm._e(),_vm._v(" working days")])]),_c('td',{staticClass:"secondary--text py-3"},[_c('div',{staticClass:"d-flex justify-center flex-nowrap P-track-rating mb-2",class:{
                      'P-track-rating-1' : item.tracking_rating === 1,
                      'P-track-rating-2' : item.tracking_rating === 2,
                      'P-track-rating-3' : item.tracking_rating === 3,
                      'P-track-rating-4' : item.tracking_rating === 4,
                    }},[_c('span',{staticClass:"me-2"}),_c('span',{staticClass:"me-2"}),_c('span',{staticClass:"me-2"}),_c('span')]),_c('p',[_vm._v(" "+_vm._s(item.tracking_rating === 1 ? 'Limited' : item.tracking_rating === 2 ? 'Basic' : item.tracking_rating === 3 ? 'Regulyar' : item.tracking_rating === 4 ? 'Excellent' : '')+" ")])]),_c('td',{staticClass:"secondary--text"},[_vm._v(_vm._s(item.description))]),_c('td',{staticClass:"secondary--text border-right-0"},[_vm._v("$"+_vm._s(item.shipment_charge_total))])])]})],2):_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"secondary--text"},[_vm._v("Courier")]),_c('th',{staticClass:"secondary--text"},[_vm._v("Delivery Time")]),_c('th',{staticClass:"secondary--text"},[_vm._v("Tracking")]),_c('th',{staticClass:"secondary--text text-wrap P-description-cont"},[_vm._v("Description")]),_c('th',{staticClass:"secondary--text border-right-0"},[_vm._v("Total Price")])])
}]

export { render, staticRenderFns }