<template>
  <div class="P-manage-address P-manage-client">
    <div class="P-manage-address-block G-flex-wrap">
      <div class="w-max-content p-y-10 p-l-10">
        <ott-switch v-model="anExistingAddress" label="An Existing Address" />
      </div>

       <div v-if="!anExistingAddress && shipTo" class="ms-4 p-y-10 w-max-content">
        <ott-switch 
          v-model="isExistingName"
          @emitFunction="setExistingName"
          label="Existing Name" 
        />
      </div>

      <div class="G-flex-wrap" v-if="!anExistingAddress">
        <div class="P-padding-33">
          <ott-input
            v-model="companyName"
            :label="shipFrom ? 'Company Name' : 'Company Name (Optional)'"
          />
        </div>
        <div v-if="shipTo" class="P-padding-33">
          <ott-input
            v-model="firstname"
            :error="$v.formData.firstname.$error"
            :disabled="isExistingName"
            :class="
              isExistingName && $v.formData.firstname.$error
                ? 'P-error'
                : ''
            "
            @emitKeypress="onlyLetters($event)"
            label="First Name*"
          />
        </div>
        <div v-if="shipTo" class="P-padding-33">
          <ott-input
            v-model="lastname"
            :error="$v.formData.lastname.$error"
            :disabled="isExistingName"
            :class="
              isExistingName && $v.formData.lastname.$error
                ? 'P-error'
                : ''
            "
            @emitKeypress="onlyLetters($event)"
            label="Last Name*"
          />
        </div>
        <div v-if="contact.allPhones.length" class="P-padding-33">
          <ott-select
            v-model="phone"
            :items="phoneList"
            :multiple="false"
            :persistentHint="true"
            :clear="true"
            label="Phone Number*"
            :error="$v.formData.phone.$error"
            selected-text="name"
          />
        </div>
        <div :class="shipFrom ? 'P-padding-100' : 'P-padding-66'">
          <ott-us-autocomplate
            :addressData="formData.address"
            :error-address="$v.formData.address.$error"
            @updateAddress="updateAddress"
            @saveAddress="saveAddress"
            ref="usAddress"
            isRequiredField
          />
        </div>
        <div class="P-padding-33">
          <ott-select-autocomplete
            v-model="country"
            :height="38"
            :items="countryList"
            :error="$v.formData.country.$error"
            :clear="true"
            outlined
            selected-text="name"
            label="Country*"
          />
        </div>
        <div class="P-padding-33">
          <ott-input
            v-model="city"
            :error="$v.formData.city.$error"
            label="City*"
          />
        </div>
        <div class="P-padding-33">
          <ott-input
            v-model="province"
            :error="$v.formData.province.$error"
            label="State / Province*"
          />
        </div>
        <div class="P-padding-33">
          <ott-input v-model="suite" label="Suite / Unit" />
        </div>
        <div class="P-padding-33">
          <ott-input
            v-model="zip"
            label="Zip Code*"
            :error="$v.formData.zip.$error"
          />
        </div>
      </div>

      <div v-else class="P-padding-100">
        <ottSelect
          v-model="existingAddress"
          @emitFunc="existingAddressSelect"
          :items="existingAddresses"
          :error="$v.formData.existingAddress.$error"
          label="Address*"
          selected-text="name"
        />
      </div>
    </div>

    <transition name="fade">
      <div v-if="errorMessage" class="my-2 w-max-content">
        <ErrorMessageContainer
          :message="errorMessage"
          width="w-max-content"
        />
      </div>
    </transition>

    <SaveResetButtons
      left-label="Cancel"
      :right-label="buttonsRightLabel"
      :left-click-func="resetFormData"
      :is-loading="isLoading"
      :right-click-func="() => saveData()"
    />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
// components
import TitleComponent from "@/components/TitleComponent";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttUsAutocomplate from "@/components/vuetifyComponents/OttUsAutocomplate";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import SaveResetButtons from "@/components/SaveResetButtons";
import allCountry from "@/constants/all-countries";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";

export default {
  name: "ManageAddress",
  components: {
    SaveResetButtons,
    OttCheckbox,
    OttSelectAutocomplete,
    OttUsAutocomplate,
    OttSelect,
    OttInput,
    OttSwitch,
    TitleComponent,
    ErrorMessageContainer
  },

  props: {
    shipFrom: {
      type: Boolean,
      default: () => false,
    },

    shipTo: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      phoneList: [],
      countryList: allCountry,
      errorMessage: ''
    };
  },
  validations: {
    formData: {
      existingAddress: {
        requiredIf: requiredIf((vm) => vm.anExistingAddress),
      },
      firstname: {
        requiredIf: requiredIf(function () {
          return this.shipTo && !this.anExistingAddress;
        }),
      },
      lastname: {
        requiredIf: requiredIf(function () {
          return this.shipTo && !this.anExistingAddress;
        }),
      },
      phone: {
        requiredIf: requiredIf((vm) => !vm.anExistingAddress && this.contact.allPhones.length),
      },
      address: {
        requiredIf: requiredIf((vm) => !vm.anExistingAddress),
      },
      country: {
        requiredIf: requiredIf((vm) => !vm.anExistingAddress),
      },
      city: {
        requiredIf: requiredIf((vm) => !vm.anExistingAddress),
      },
      zip: {
        requiredIf: requiredIf((vm) => !vm.anExistingAddress),
      },
      province: {
        requiredIf: requiredIf((vm) => !vm.anExistingAddress),
      },
    },
  },
  computed: {
    ...mapState({
      shipFromFormData: (state) => state.shippingClients.shipFromFormData,
      shipToFormData: (state) => state.shippingClients.shipToFormData,
      shipFromAddress: (state) => state.shippingClients.shipFromAddress,
      shipToAddress: (state) => state.shippingClients.shipToAddress,
      isOpenFromForm: (state) => state.shippingClients.isOpenFromForm,
      isOpenToForm: (state) => state.shippingClients.isOpenToForm,
      ottProviderId: (state) => state.auth.user.provider.id,
      companyExistingAddresses: (state) =>
        state.companyAddressesAdmin.shippingAddresses,
      clientExistingAddresses: (state) =>
        state.addressClient.shippingAddresses,
      clientAllAddresses: (state) => state.addressClient.allAddresses,
      clientPersonalInfo: state => state.personalInfoClient,
      clientId: (state) => state.clientsModule.clientId,
      shipFromAddressId: (state) => state.shippingClients.shipFromAddressId,
      shipToAddressId: (state) => state.shippingClients.shipToAddressId,

      addressClient: (state) => state.addressClient,
      contact: (state) => state.contactClient,
      allAddresses: (state) => state.addressClient.allAddresses,
      isLoading: (state) => state.appModule.isLoading,
    }),

    existingAddresses() {
      if (this.shipFrom) {
        return this.companyExistingAddresses;
      }

      if (this.shipTo) {
        return this.clientExistingAddresses;
      }
    },

    buttonsRightLabel() {
      if (this.shipFrom) {
        if (this.shipFromAddress) {
          return "Save";
        }

        if (!this.shipFromAddress) {
          return "Add";
        }
      }

      if (this.shipTo) {
        if (this.shipToAddress) {
          return "Save";
        }

        if (!this.shipToAddress) {
          return "Add";
        }
      }
    },

    formData() {
      if (this.shipFrom) {
        return this.shipFromFormData;
      }

      if (this.shipTo) {
        return this.shipToFormData;
      }
    },

    existingAddress: {
      get() {
        return this.formData.existingAddress;
      },
      set(existingAddress) {
        if (this.shipFrom) {
          this.setShipFromFormData({ existingAddress });
        }

        if (this.shipTo) {
          this.setShipToFormData({ existingAddress });
        }
      },
    },

    isExistingName: {
      get() {
        return this.formData.isExistingName
      },
      set(isExistingName) {
        this.setShipToFormData({ isExistingName });
      }
    },

    companyName: {
      get() {
        return this.formData.companyName;
      },
      set(companyName) {
        if (this.shipFrom) {
          this.setShipFromFormData({ companyName });
        }

        if (this.shipTo) {
          this.setShipToFormData({ companyName });
        }
      },
    },

    firstname: {
      get() {
        return this.formData.firstname;
      },
      set(value) {
        this.setShipToFormData({ firstname: value });
      },
    },
    lastname: {
      get() {
        return this.formData.lastname;
      },
      set(value) {
        this.setShipToFormData({ lastname: value });
      },
    },
    phone: {
      get() {
        return this.formData.phone;
      },
      set(phone) {
        if (this.shipFrom) {
          this.setShipFromFormData({ phone });
        }

        if (this.shipTo) {
          this.setShipToFormData({ phone });
        }
      },
    },
    address: {
      get() {
        return this.formData.address;
      },
      set(address) {
        if (this.shipFrom) {
          this.setShipFromFormData({ address });
        }

        if (this.shipTo) {
          this.setShipToFormData({ address });
        }
      },
    },
    country: {
      get() {
        return this.formData.country;
      },
      set(coutry) {
        if (this.shipFrom) {
          this.setShipFromFormData({ coutry });
        }

        if (this.shipTo) {
          this.setShipToFormData({ address });
        }
      },
    },
    city: {
      get() {
        return this.formData.city;
      },
      set(city) {
        if (this.shipFrom) {
          this.setShipFromFormData({ city });
        }

        if (this.shipTo) {
          this.setShipToFormData({ city });
        }
      },
    },
    province: {
      get() {
        return this.formData.province;
      },
      set(province) {
        if (this.shipFrom) {
          this.setShipFromFormData({ province });
        }

        if (this.shipTo) {
          this.setShipToFormData({ province });
        }
      },
    },
    suite: {
      get() {
        if (this.shipFrom) {
          return this.formData.unit;
        }

        if (this.shipTo) {
          return this.formData.suite;
        }
      },
      set(value) {
        if (this.shipFrom) {
          this.setShipFromFormData({ unit: value });
        }

        if (this.shipTo) {
          this.setShipToFormData({ suite: value });
        }
      },
    },
    zip: {
      get() {
        return this.formData.zip;
      },
      set(zip) {
        if (this.shipFrom) {
          this.setShipFromFormData({ zip });
        }

        if (this.shipTo) {
          this.setShipToFormData({ zip });
        }
      },
    },
    anExistingAddress: {
      get() {
        return this.formData.anExistingAddress;
      },
      set(value) {
        if (this.shipFrom) {
          this.setShipFromFormData({ anExistingAddress: value });
        }

        if (this.shipTo) {
          this.setShipToFormData({ anExistingAddress: value });
        }
      },
    },
  },

  watch: {
    'contact.allPhones'(allPhones) {
      this.phoneList = []
      if (allPhones.length) {
        allPhones.map(item => {
          this.phoneList.push({
            name: item.phone,
            value: item.phone
          })
        })
      }
    }
  },

  async created() {
    this.errorMessage = ''
    await this.getCompanyAddresses(this.ottProviderId);
    await this.getClientAddresses();

    if (this.$route.name !== 'Search') {
      await this.getClientPersonalInfo({ clientId: this.clientId });
    }

    if (this.contact.allPhones.length) {
      this.contact.allPhones.map((item) => {
        this.phoneList.push({
          name: item.phone,
          value: item.phone,
        });
      });
    }
  },
  methods: {
    ...mapActions({
      addCompanyAddress: "companyAddressesAdmin/addCompanyAddress",
      getCompanyAddresses: "companyAddressesAdmin/getCompanyAddresses",
      manageCompanyAddress: "companyAddressesAdmin/manageCompanyAddress",
      addClientAddress: "addressClient/addContactAddress",
      getClientAddresses: "addressClient/fetchAddress",
      getClientPersonalInfo: "personalInfoClient/getPersonalInfo",
    }),

    ...mapMutations({
      setData: "shippingClients/setData",
      clientAddNewAddress: "addressClient/addNewAddress",
      setShipFromFormData: "shippingClients/setShipFromFormData",
      setShipToFormData: "shippingClients/setShipToFormData",
      resetShipFromForm: "shippingClients/resetShipFromForm",
      resetShipToForm: "shippingClients/resetShipToForm",
    }),

    // buttonsRightClickFunc() {
    //   if (this.shipFrom) {
    //     if (this.shipFromAddress) {
    //       this.editData();
    //     }

    //     if (!this.shipFromAddress) {
    //       this.saveData();
    //     }
    //   }

    //   if (this.shipTo) {
    //     if (this.shipToAddress) {
    //       this.editData();
    //     }

    //     if (!this.shipToAddress) {
    //       this.saveData();
    //     }
    //   }
    // },

    /**
     * Add Address
     * **/

    async saveData() {
      this.$v.formData.$touch();

      if (!this.$v.formData.$error) {
        // After save button, save selected address in Company Settings Addresses(From) or in Client Addresses(To)
        let formData = Object.assign({}, this.formData),
          addressData = {},
          address = "";

        // delete empy keys
        for (const key in formData) {
          if (!formData[key]) {
            delete formData[key];
          }
        }

        // After save/add create address as text for showing
        if (!this.anExistingAddress) {
          delete formData.anExistingAddress;
          delete formData.existingAddress;
          delete formData.isExistingName;

          if (this.formData.address) {
            address = address + this.formData.address;
          }
          if (this.formData.suite) {
            address = address + " " + this.formData.suite;
          }
          if (this.formData.city) {
            address = address + " " + this.formData.city;
          }
          if (this.formData.province) {
            address = address + " " + this.formData.province;
          }
          if (this.formData.zip) {
            address = address + " " + this.formData.zip;
          }
          if (this.formData.country && this.formData.country !== "US") {
            address = address + " " + this.formData.country;
          }
        } else {
          this.existingAddresses.map((item) => {
            if (item.value === this.existingAddress) {
              address = item.name;
            }
          });
        }

        // Ship From ADD/EDIT functions
        if (this.shipFrom) {
          //Change phone key syntax if is shipFrom
          for (const key in formData) {
            if (key === "phone") {
              formData.phone = {
                number: formData[key] + "",
              };
            }
          }

          formData.state = formData.province;
          delete formData.province; //get province key as satte and remove it,
          //becouse for company settings address api that key's name is changed

          addressData = {
            ...formData,
            providerId: this.ottProviderId,
          };

          if (!this.anExistingAddress) {
            if (!this.shipFromAddressId) { // ADD address
              await this.addCompanyAddress(addressData)
                .then((response) => {
                  this.setData({ shipFromAddressId : response.id })
                });

            } else { // EDIT address
              await this.manageCompanyAddress({ id: this.shipFromAddressId, formData })
            } 
          }
          
          this.setData({ shipFromAddress: address });
        }

        // Ship To ADD/EDIT functions
        if (this.shipTo) {
          this.clientAddNewAddress({ ...formData })

          if (!this.anExistingAddress && this.contact.allPhones.length) {
            if (!this.shipToAddressId) { // ADD Address
              await this.addClientAddress({ addresses: this.clientAllAddresses })
                .then((response) => {
                  this.setData({ shipToAddressId : response.id })
                });

            } else { // EDIT Address
              let addresses = Object.assign([], this.clientAllAddresses);
              addresses.map((item) => {
                if (item.id === this.shipToAddressId) {
                  item.companyName = formData.companyName;
                  item.firstname = formData.firstname;
                  item.lastname = formData.lastname;
                  item.phone = formData.phone;
                  item.address = formData.address;
                  item.country = formData.country;
                  item.city =  formData.city;
                  item.province = formData.province; // state / province
                  item.suite = formData.suite; // suite / unit
                  item.zip = formData.zip;
                  // item.lat = formData.lat;
                  // item.long = formData.long;
                }
              })
              await this.addClientAddress({ addresses })
            }
          }

          if (!this.contact.allPhones.length) {
            this.errorMessage = 'The client has not a phone number, please add phone number before adding a address...'
          }

          this.setData({ shipToAddress: address });
        }

        this.closeFormData();
      }
    },

    existingAddressSelect($event) {
      if (this.shipFrom) {
        this.setData({ shipFromAddressId: $event })
      }

      if (this.shipTo) {
        this.setData({ shipToAddressId: $event })
      }
    },

    resetFormData() {
      if (this.shipFrom) {
        this.resetShipFromForm();
        this.setData({ isOpenFromForm: false });
      }

      if (this.shipTo) {
        this.resetShipToForm();
        this.setData({ isOpenToForm: false });
      }

      this.anExistingAddress = false;
    },

    closeFormData() {
      if (this.shipFrom) {
        this.setData({ isOpenFromForm: false });
      }

      if (this.shipTo) {
        this.setData({ isOpenToForm: false });
      }
    },

    /**
     * Update address autocomplete SmartStreet
     * **/

    updateAddress() {
      const [addressData] = arguments;
      let countryValue = allCountry.filter(
        (x) => x.name === addressData.country
      )[0].value;

      if (this.shipFrom) {
        this.setShipFromFormData({
          address: addressData.fullAddress,
          country: countryValue,
          city: addressData.city,
          province: addressData.province,
          unit: addressData.suite,
          zip: addressData.zipCode,
          // lat: addressData.lat,
          // long: addressData.long,
        });
      }

      if (this.shipTo) {
        this.setShipToFormData({
          address: addressData.fullAddress,
          country: countryValue,
          city: addressData.city,
          province: addressData.province,
          suite: addressData.suite,
          zip: addressData.zipCode,
          lat: addressData.lat,
          long: addressData.long,
        });
      }
    },

    saveAddress() {
      this.errorMessage = ''
      const [address] = arguments;
      if (this.shipFrom) {
        this.setShipFromFormData({ address });
      }

      if (this.shipTo) {
        this.setShipToFormData({ address });
      }
    },

    /**
     * Only letters and spaces - regex
     * **/
    onlyLetters(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[a-zA-Z\s]*$/.test(char)) return true;
      else event.preventDefault();
    },

    setExistingName() {
      if (this.isExistingName) {
        this.firstname = this.clientPersonalInfo.formData.firstname
        this.lastname = this.clientPersonalInfo.formData.lastname
      }  else {
        this.firstname = ''
        this.lastname = ''
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-manage-address-block {
  margin: 0 -10px;
}

.P-manage-address-block::v-deep {
  .v-input--switch {
    margin: 0;
  }
}

.P-checkbox {
  padding: 8px 8px 0;

  .Ott-checkbox-cont + .Ott-checkbox-cont {
    margin-left: 20px;
  }
}

.P-read-only-text {
  color: $neutral-color;
  font-size: $txt10;
  margin-left: 18px;
  margin-bottom: -6px;
}

.P-error::v-deep {
  .v-input--is-disabled,
  fieldset,
  .v-label {
    border-width: 1.5px;
    color: $danger-color-dark !important;
    caret-color: $danger-color-dark !important;
  }
}

.p-y-10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.P-error::v-deep {
  .v-input--is-disabled,
  fieldset,
  .v-label {
    border-width: 1.5px;
    color: $danger-color-dark !important;
    caret-color: $danger-color-dark !important;
  }
}
</style>