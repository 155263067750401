import { render, staticRenderFns } from "./AddressesStep.vue?vue&type=template&id=60dbe603&scoped=true"
import script from "./AddressesStep.vue?vue&type=script&lang=js"
export * from "./AddressesStep.vue?vue&type=script&lang=js"
import style0 from "./AddressesStep.vue?vue&type=style&index=0&id=60dbe603&prod&scoped=true&lang=scss"
import style1 from "./AddressesStep.vue?vue&type=style&index=1&id=60dbe603&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60dbe603",
  null
  
)

export default component.exports