<template>
  <div class="P-confirm-success position-relative pt-6">
      <span class="icon-checked"></span>

      <div class="P-confirm-send G-justify-center ">
        <div class="P-confirm-send-block flex-column G-flex-center">
          <transition name="fade">
            <img 
              :src="invoiceImg"
              @click="isShowImage = true"
              class="P-invoice-img" 
              alt="Invoice Image"
            />
          </transition>
          
          <div class="d-flex flex-column w-70 mt-4">
            <ott-button text="Print Receipt" :click="handleClickInvoice" custom-class="neutral-sm-btn" />
            
            <!-- -->
            <div v-if="isExistTwilio || isExistSmtp" class="my-3">
              <ott-button text="Send Receipt" :click="() => showWarningModal = true" custom-class="neutral-sm-btn" />
            </div>
          </div>
        </div>
  <!--      <div class="P-confirm-send-block">-->
  <!--        <span class="P-confirm-send-by">Label</span>-->
  <!--        <p class="P-confirm-email">Generate Label on EasyPay (Asynchronous Process)</p>-->
  <!--        <ott-button text="Generate Label" :click="handleClickLabel" custom-class="neutral-sm-btn" />-->
  <!--      </div>-->
      </div>

    <image-modal
      v-if="isShowImage"
      :isOpenModal="isShowImage"
      :imageSrc="invoiceImg"
      :width="400"
      @closeModal="isShowImage = false"
      modal-title="Invoice"
    />

    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      :modalText="warningModalText"  
      btnText="Send"
      modalHeading="Send Invoice?" 
      @closeModal="closeWarningModal" 
      @btnFunction="warningModalBtnFunc"
    >
      <div class="mb-58 send-select-cont" slot="body">
        <br />
        <p class="mb-3 txt-14"><i>{{ warningModalSendLabel }}</i></p>
        <transition name="slide">
          <div v-show="sendType.split('/')[0] === 'phone' && (!validPhoneNumber || !isValidateNumber)" class="mb-3">
            <ott-phone-number
              v-model="sendPhoneNumber"
              :error="!validPhoneNumber || !isValidateNumber"
              @onInput="onInputPhoneNumber"
            />
          </div>
        </transition>

        <ott-select
          v-model="sendType"
          :items="invoiceSendTypes"
          @emitFunc="sendTypeSelect"
          selected-text="name"
          selected-value="value"
          label="Send Receipt"
        />
      </div>
    </WarningModal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import ImageModal from "@/components/ImageModal";
import WarningModal from "@/components/WarningModal";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";

export default {
  name: "ConfirmationSuccess",
  components: { OttButton, OttSelect, ImageModal, WarningModal, OttPhoneNumber },
  data() {
    return {
      sendType: '',
      invoiceSendType: {
        type: null,
        value: null
      },
      invoiceSendTypes: [],
      emailsList: [],
      phonesList: [],
      isShowImage: false,
      
      showWarningModal: false,
      warningModalIsDangerButton: false,
      warningModalIsLoading: false,
      selectedInvoiceNumber: null,
      selectedInvoiceId: null,
      selectedInvoiceClient: null,
      loading: false,
      sendPhoneNumber: '',
      disableSend: false, 
      validPhoneNumber: true,
      isValidateNumber: true,
      isExistSmtp: false,
      isExistTwilio: false
    }
  },
  computed: {
    ...mapState({
      invoiceData: (state) => state.checkoutClients.invoiceData,
      clientId: (state) => state.clientsModule.clientId 
    }),

    warningModalSendLabel() {
      if (this.invoiceSendType.type === 'email_phone' || !this.invoiceSendType.type || this.invoiceSendType.type === 'email') {
          return 'Please select send type...'
      }

      if (this.invoiceSendType.type === 'phone' && (this.validPhoneNumber && this.isValidateNumber)) {
          return 'Please select send type...'
      }

      if (this.invoiceSendType.type === 'phone' && (!this.validPhoneNumber || !this.isValidateNumber)) {
          return 'Please update phone number to send...'
      }
    },

    invoiceImg() {
      return `${process.env.VUE_APP_API_URL}invoices/view/${this.invoiceData.id}?type=2&jpeg=true`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>send</ins></span> this invoice! <br />  If you're not sure, you can cancel this operation.`
    },
  },
  async created() {
    await this.getSendInfos()
  },
  methods: {
    ...mapActions({
      generateLabel: "shippingClients/generateLabel",
      getClientData: "clientsModule/getClientData",
      sendInvoice: "checkoutClients/sendInvoice",
      getClientContacts: "contactClient/getContact",
      addClientPhone: "contactClient/addContactPhone",
    }),

    handleClickLabel() {
      this.generateLabel();
    },
    handleClickInvoice() {
      window.open(`${process.env.VUE_APP_API_URL}invoices/view/${this.invoiceData.id}?type=2`, '_blank');
    },

    async closeWarningModal() {
      this.showWarningModal = false;
      this.warningModalIsLoading = false;
      this.selectedInvoiceNumber = null;
      this.sendType = '';
      this.invoiceSendType.type = null;
      this.invoiceSendType.value = null;
      this.selectedInvoiceId = null;
      this.selectedInvoiceClient = null;
      this.sendPhoneNumber = '';
      this.validPhoneNumber = true;
      this.isValidateNumber = true;
      this.showPhoneError = false;
      this.disableSend = false;

      // await this.getInvoiceList(this.filterData)
    },

    async onInputPhoneNumber(phone, obj) {
      if (this.sendPhoneNumber) {
        this.validPhoneNumber = obj.isValid;  
        // Remove non-alphanumeric characters from the input
        const sanitizedInput = obj.number.input.replace(/[^A-Za-z0-9]/g, '');
        // Check if the sanitized input is a valid number
        this.isValidateNumber = !isNaN(Number(sanitizedInput));

        if (this.invoiceSendType.type === 'phone' && (this.validPhoneNumber && this.isValidateNumber)) {
          this.editClientPhone()
        }
      }
    },

    async editClientPhone() {
      // !this.isDuplicatedPhone
      if (this.invoiceSendType.type === 'phone' && (this.validPhoneNumber && this.isValidateNumber)) {

        // Editing phone update in phones list
        if (this.phonesList.length) {
          this.phonesList.forEach((item) => {
            if (item?.phone === this.sendType.split('/')[1]) {
              item.phone = this.sendPhoneNumber

              delete item?.updatedAt
              delete item?.createdAt
              delete item?.id
              delete item?.value
            }
          });
        }

        // update editing phone in the invoiceSendTypes
        this.invoiceSendTypes.forEach(item => {
          if (item.value.split('/')[1] === this.invoiceSendType.value) {
            item.name = `Phone ${this.sendPhoneNumber}`
            item.value = `phone/${this.sendPhoneNumber}`
          }
        })

        await this.addClientPhone({ phones: this.phonesList, clientId: this.clientId }).finally(() => this.showWarningModal = false)
      }
    },

    async warningModalBtnFunc() {
      this.warningModalIsLoading = true

      await this.sendInvoice({id: this.invoiceData?.id, data: this.invoiceSendType}).then((response)=>{
        this.createToastMessage( { success: response.data.success, message: response.data.message})
      }).finally(() => this.closeWarningModal())
      
      this.warningModalIsLoading = false
    },

    createToastMessage(response) {
      const toastOptions = {
        msg: response.message,
        position: "toast-bottom-right",
        defaultTimeout: 500
      }
  
      if (response.success) {
        toastOptions.type = "success"
        toastOptions.style = { 
          backgroundColor: "#01B2B8", 
          width: "max-content", 
          textTransform: "capitalize",
          opacity: '1'
        }
        this.$toastr.Add(toastOptions);
      } else {
        toastOptions.type = "error",
        toastOptions.style = { 
          backgroundColor: "red", 
          width: "max-content", 
          textTransform: "capitalize",
          opacity: '1'
        }

        this.$toastr.Add(toastOptions);
      }
    },

    async sendTypeSelect() {
      if (this.sendType === 'email_phone') {
        this.invoiceSendType.type = 'email_phone'
        this.invoiceSendType.value = ''
      }

      if (this.sendType !== 'email_phone') {
        this.invoiceSendType.type = this.sendType.split('/')[0]
        this.invoiceSendType.value = this.sendType.split('/')[1]

        if (this.invoiceSendType.type === 'phone') {
          this.sendPhoneNumber = this.sendType.split('/')[1]

          if (!this.isValidateNumber || !this.validPhoneNumber) {
            this.disableSend = false
          } else {
            this.disableSend = true
          }
        }
      }
    },
    async getSendInfos() {
      const clientData = await this.getClientData(this.clientId) ?? {}
      this.emailsList = clientData?.emails ?? []
      this.phonesList = clientData?.phones ?? []
      this.emailsList = this.emailsList.filter(item => item.forContactInvoice)
      this.phonesList = this.phonesList.filter(item => item.forMessages)

      this.isExistSmtp = false
      this.isExistTwilio = false

      /* Checking transaction's clint's provider has SMTP or has Twilio */
      if (this.invoiceData?.from_client?.provider) {
        if ('hasValidTwilio' in this.invoiceData?.from_client?.provider) {
          this.isExistTwilio = this.invoiceData?.from_client?.provider.hasValidTwilio
        }

        if ('hasValidSmtp' in this.invoiceData.from_client.provider) {
          this.isExistSmtp = this.invoiceData.from_client.provider.hasValidSmtp
        }
      }

      if (this.invoiceData?.to_client?.provider) {
        if ('hasValidTwilio' in this.invoiceData?.to_client?.provider) {
          this.isExistTwilio = this.invoiceData?.to_client?.provider.hasValidTwilio
        }
        
        if ('hasValidSmtp' in this.invoiceData?.to_client?.provider) {
          this.isExistSmtp = this.invoiceData.to_client.provider.hasValidSmtp
        }
      }

      if (this.isExistSmtp && this.isExistTwilio) {
        this.invoiceSendTypes = [
          {
            name: 'Email & Phone',
            value: 'email_phone'
          }
        ];
      }
      /***************************************************************/

      if (this.emailsList.length && this.isExistSmtp) {
        //Setting selected client's emails
        this.emailsList.map(item => {
          this.invoiceSendTypes.push({
            name: `Email ${item.email}`,
            value: `email/${item.email}`
          })
        })
      }

      if (this.phonesList.length && this.isExistTwilio) {
        //Setting selected client's phones
        this.phonesList.map(item => {
          this.invoiceSendTypes.push({
            name: `Phone ${item.phone}`,
            value: `phone/${item.phone}`
          })
        })
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "./confirm";

.P-confirm-success {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.P-invoice-img {
  max-height: 299px;
  cursor: pointer;
}
</style>