<template>
  <ott-dialog
    :is-open="checkoutClients.modal.isOpen"
    :show-footer="false"
    :width="1500"
    :show-header="false"
    @outside="cancel"
    @resetDataModal="cancel"
    close-value="addProvider"
  >
    <template v-slot:modalBody>
      <div class="P-checkout-block">
        <CheckoutHeader @close-modal="cancel" />
        <div
          class="P-checkout-body G-flex"
          v-if="checkoutClients.activeStep !== 4"
        >
          <div class="P-checkout-body-left">
            <div class="P-checkout-container">
              <ShippingStep
                v-if="
                  accessShipping || invoiceData.isShipping &&
                  checkoutClients.activeStep === 1
                "
              />
              <PaymentStep
                v-if="
                  ((!accessShipping && !invoiceData.isShipping) && checkoutClients.activeStep === 1) ||
                  ((accessShipping || invoiceData.isShipping) &&
                    checkoutClients.activeStep === 2)
                "
                ref="paymentStep"
              />
              <ConfirmStep
                v-if="
                  (checkoutClients.activeStep === 2 && (!accessShipping && !invoiceData.isShipping)) ||
                  checkoutClients.activeStep === 3
                "
              />
            </div>
            <div
              class="P-checkout-footer align-center d-flex justify-space-between"
            >
              <div class="P-cancel-button">
                <OttButtonLoader
                  @onClick="cancel"
                  :is-loading="false"
                  :button-text="'Cancel'"
                  className="neutral-sm-btn"
                />
              </div>
              <span v-if="checkoutClients.hasError" style="color: red">{{ checkoutClients.errorMessage }}</span>
              <span v-if="shippingClients.hasError" style="color: red">{{ shippingClients.errorMessage }}</span>

              <transition name="fade">
                <div
                  v-if="
                    accessShipping &&
                    shipFromAddressId &&
                    shipToAddressId &&
                    checkoutClients.activeStep === 1
                  "
                  class="d-flex flex-column align-center"
                >
                  <span class="P-shipping-steps">Shipping Steps</span>

                  <div class="d-flex align-center justify-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          @click="changeShippingStep(1)"
                          :class="{
                            'P-shipping-dot-active': shippingStep === 1,
                          }"
                          class="mdi mdi-numeric-1-circle-outline P-shipping-dot"
                        >
                        </span>
                      </template>
                      <span>Shipping Addresses</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          @click="changeShippingStep(2)"
                          :class="{
                            'P-shipping-dot-active': shippingStep === 2,
                          }"
                          class="mdi mdi-numeric-2-circle-outline P-shipping-dot"
                        >
                        </span>
                      </template>
                      <span>Shipment Information</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          @click="changeShippingStep(3)"
                          :class="{
                            'P-shipping-dot-active': shippingStep === 3,
                          }"
                          class="mdi mdi-numeric-3-circle-outline P-shipping-dot"
                        >
                        </span>
                      </template>
                      <span>Choose Courier</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          @click="changeShippingStep(4)"
                          :class="{
                            'P-shipping-dot-active': shippingStep === 4,
                          }"
                          class="mdi mdi-numeric-4-circle-outline P-shipping-dot me-0"
                        >
                        </span>
                      </template>
                      <span>Handover Options & Add-ons</span>
                    </v-tooltip>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="P-checkout-body-right G-flex">
            <transition name="fade">
              <OrderDetails v-if="shipFromAddress && shipToAddress" />
            </transition>

            <div :class="{ 'border-top': shipFromAddress && shipToAddress }">
              <OrderSummary />
            </div>
            <!-- <BalanceAndCreditHistory v-if="checkoutClients.activeStep === 2" /> need for check -->
            <div class="mt-5">
              <ErrorMessageContainer
                v-if="paymentState === 0"
                :width="100"
                :message="paymentErrorMessage"
              />
              <span v-if="paymentState === 1">{{ paymentMessage }}</span>
            </div>
            <ott-button-loader
              @onClick="nextOrCheckout"
              :is-loading="isLoading"
              :button-text="nextButtonLabel"
              :icon-after="nextButtonLabel === 'Next'"
              className="primary-sm-btn P-login-btn P-next-btn"
            />
          </div>
        </div>
        <div
          class="P-checkout-body G-flex"
          v-if="checkoutClients.activeStep === 4"
        >
          <ConfirmationSuccess />
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { eventBus } from "@/main";

import ShippingStep from "./components/shipping/ShippingStep";
import OrderDetails from "./components/orderDetails/OrderDetails";
import PaymentStep from "./components/payment/PaymentStep";
import ConfirmStep from "./components/confirmation/ConfirmStep";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer.vue";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import CheckoutHeader from "./CheckoutHeader";
import OrderSummary from "./components/orderSummary/OrderSummary";
import BalanceAndCreditHistory from "./components/orderSummary/BalanceAndCreditHistory";
import ConfirmationSuccess from "@/views/clients/modals/checkout/components/confirmation/ConfirmationSuccess.vue";
import CheckoutClientModalMixin from "@/views/clients/modals/checkout/CheckoutClientModalMixin";

export default {
  name: "CheckoutClientModal",
  components: {
    ShippingStep,
    OrderDetails,
    PaymentStep,
    ConfirmStep,
    ConfirmationSuccess,
    BalanceAndCreditHistory,
    OrderSummary,
    CheckoutHeader,
    ErrorMessageContainer,
    OttButtonLoader,
    OttButton,
    OttDialog,
  },

  data() {
    return {
      error: true,
      errorMessage: "message",
      payData: {},
    };
  },

  computed: {
    shippingClients() {
      return shippingClients
    },
    ...mapState({
      checkoutClients: (state) => state.checkoutClients,
      shippingClients: (state) => state.shippingClients,
      accessShipping: (state) => state.checkoutClients.accessShipping,
      // Shipping
      shippingStep: (state) => state.shippingClients.shippingStep,
      shipFromAddress: (state) => state.shippingClients.shipFromAddress,
      shipToAddress: (state) => state.shippingClients.shipToAddress,
      shipInfoBoxesData: (state) => state.shippingClients.shipInfoBoxesData,
      selectedCourier: (state) => state.shippingClients.selectedCourier,
      shipFromAddressId: (state) => state.shippingClients.shipFromAddressId,
      shipToAddressId: (state) => state.shippingClients.shipToAddressId,
      couriersList: (state) => state.shippingClients.couriersList,
      isPremiumShipping: (state) => state.shippingClients.isPremiumShipping,
      isStandartPickup: (state) => state.shippingClients.isStandartPickup,
      // ------- //
      packagesPayBtnLabel: (state) => state.packagesClients.packagesPayBtnLabel,
      balance: (state) => state.balanceCreditClient.balanceManage.balance,
      paymentState: (state) => state.checkoutClients.paymentState,
      paymentErrorMessage: (state) => state.checkoutClients.paymentErrorMessage,
      paymentMessage: (state) => state.checkoutClients.paymentMessage,
      locationComments: (state) => state.locationClient.locationComments,
      isLoading: (state) => state.appModule.isLoading,
      invoiceData: (state) => state.checkoutClients.invoiceData,
    }),

    isExistNewEquipments() {
      return this.invoiceData?.payload?.equipments.length;
    },

    nextButtonLabel() {
      let label = "Next"

      if (this.checkoutClients.activeStep === 2) {
        label = `${this.invoiceData.payloadCalculated.btnText} ${
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(this.invoiceData.totalAmount)
        }`
        // label = this.packagesPayBtnLabel
      }

      return label
    }
  },

  async created() {
    await this.getBalanceCredit();

    eventBus.$on("payFromBalance", async () => {
      if (this.checkoutClients.payFromBalance > 0) {
        this.getPayData();
        await this.payInvoiceFunc();
      }
    });
  },
  destroyed() {
    this.resetCheckoutModal();
    this.resetShippingState();
    this.resetEquipmentsFormData();
    this.closeCheckoutModal();
  },
  mixins: [CheckoutClientModalMixin],
  methods: {
    ...mapActions({
      getBalanceCredit: "balanceCreditClient/getBalanceCredit",
      payInvoice: "checkoutClients/payInvoice",
      getLocations: "locationClient/getLocations",
      editLocation: "locationClient/editLocation",
      checkShipping: "shippingClients/checkShipping",
      getTransactionList: 'transactionsModule/getTransactionList',
      getBillsList: 'clientBillsModule/getBillsList',
      getPaymentMethods: 'paymentMethodClients/getPaymentMethod',
    }),

    ...mapMutations({
      resetCheckoutModal: "checkoutClients/resetState",
      resetClientPackagesTab: "packagesClients/resetState",
      resetEquipmentsFormData: "equipments/resetFormData",
      resetClientEquipments: "equipments/resetState",
      resetShippingState: "shippingClients/resetState",
      resetClientPaymentMethods: "paymentMethodClients/resetData",
      changeStep: "checkoutClients/changeStep",
      setCheckoutData: "checkoutClients/setData",
      setShippingData: "shippingClients/setData",
      setDataLocations: "locationClient/setData",
      setClientsData: "clientsModule/setData",
      closeCheckoutModal: "checkoutClients/closeModal",
    }),

    async changeShippingStep(step) {
      if (step <= this.shippingStep) {
        this.setShippingData({ shippingStep: step });
      } else {

        if (step - this.shippingStep === 1) {
          await this.nextShippingStep();
        }
      }
    },
    async cancel() {
      this.resetClientPackagesTab();
      this.resetClientEquipments();
      this.resetClientPaymentMethods();
      this.setClientsData({ clientProviderId: null })
      await this.getPaymentMethods({clientId: this.clientId});
      await this.getLocations().then((r) => {
        if (r) {
          this.resetCheckoutModal();
          this.resetEquipmentsFormData();
          this.resetShippingState();
        }
      });
      this.closeCheckoutModal();
    },

    changeCheckoutStepWithShipping() {
      if ( // already created shipping
        this.invoiceData.isShipping && this.checkoutClients.activeStep === 2
      ) {
        this.changeStep(3);
      } else { // without shipping
        this.changeStep(2);
      }
    },

    async nextOrCheckout() {
      if (
          !this.accessShipping &&
        // Checkout step without shipping
          ((!this.invoiceData.isShipping && this.checkoutClients.activeStep === 1) ||
        (this.invoiceData.isShipping && this.checkoutClients.activeStep === 2))
      ) {
        const creditCard =
          this.$refs.paymentStep.$refs.paymentListCheckout.$refs.creditCard;
        const bankAccount =
          this.$refs.paymentStep.$refs.paymentListCheckout.$refs.bankAccount;
        const cash =
          this.$refs.paymentStep.$refs.paymentListCheckout.$refs.cash;
        const check =
          this.$refs.paymentStep.$refs.paymentListCheckout.$refs.check;
        const moneyOrder =
          this.$refs.paymentStep.$refs.paymentListCheckout.$refs.moneyOrder;

        switch (this.activePaymentType) {
          case 'saved': // Saved Payment Methods
            this.getPayData(); // get pey data
            this.changeCheckoutStepWithShipping()
            break;
          case 'card': // Credit Card
            //credit card validation
            creditCard.$v.$touch(); // TODO isSquare
            if (creditCard.square()) {
              const tokenized = await creditCard.tokenize();  
              if (tokenized.status) {
                this.getPayData(); // get pey data
                this.changeCheckoutStepWithShipping();
              }
            } else if (!creditCard.$v.$error) {
              this.getPayData(); // get pey data
                if (this.creditCard.save) {
                  await this.saveCreditCard()
                }
              this.changeCheckoutStepWithShipping()
            }
            break;

          case 'bankTransfer': // Bank Account
            //bank account validation
            bankAccount.$v.$touch();
            if (!bankAccount.$v.$error) {
              this.getPayData(); // get pey data
              this.changeCheckoutStepWithShipping()
            }
            break;

          case 'check': // Check
            //check validation
            if (!this.invoiceData?.payloadCalculated?.refund)
              check.$v.$touch();
            if (!check.$v.$error) {
              this.getPayData(); // get pey data
              this.changeCheckoutStepWithShipping()
            }
            break;

          case 'cash': // Cash
              this.getPayData(); // get pey data
              this.changeCheckoutStepWithShipping()
            break;

          case 'moneyOrder': // Money Order
            // Money Order validation
            moneyOrder.$v.$touch();
            if (!moneyOrder.$v.$error) {
              this.getPayData(); // get pey data
              this.changeCheckoutStepWithShipping()
            }
            break;

          default:
            this.changeStep(this.checkoutClients.activeStep);
        }
      }
      // Shipping Step
      else if (
        this.accessShipping
      ) {
        await this.nextShippingStep();
      }  
      else if (
        ( // already created shipping
          this.invoiceData.isShipping && this.checkoutClients.activeStep === 3
        ) || 
        (  
          !this.invoiceData.isShipping && this.checkoutClients.activeStep === 2
        )
      ) {
        await this.payInvoiceFunc();
      }
    },

    async payInvoiceFunc() {

      this.payInvoice(this.payData).then(async (r) => {
        if (!r.status) {
          this.setCheckoutData({
            paymentState: 0,
            paymentErrorMessage: r.message ? r.message : `error while paying invoice`,
          });
          // const toastOptions = {
          //   msg: 'error while paying invoice',
          //   position: "toast-bottom-right",
          //   defaultTimeout: 500,
          //   style: {
          //     backgroundColor: "#01B2B8",
          //     width: "max-content",
          //     textTransform: "capitalize",
          //     opacity: '1'
          //   }
          // }
          // this.$toastr.Add(toastOptions);
        } else {
          if (r.invoice && r.invoice.state === 1) {
            this.setCheckoutData({ activeStep: 4 });
          }
          if (r.transaction && r.transaction.state !== 1) {
            this.setCheckoutData({
              paymentState: 0,
              paymentErrorMessage: r.transaction.stateMessage,
            });
          }
          this.resetClientPackagesTab();
        }

        /*
          check if pay doing successfully, and there are location comments, 
          save comments for locations.
          Get locations list if active route is general search, for update locations list, statuses
        */

        if (r.invoice.state === 1) {
          if (this.locationComments.length) {
            this.locationComments.map(async (item) => {
              if (item.comment) {
                await this.editLocation({ ...item });
              }
            });
          }

          if (this.$route.name === "Search") {
            await this.getLocations();
          }

          this.setDataLocations({ locationComments: [] });
        }

        if (this.$route.name === 'Search' ) {
          await this.getTransactionList()
          await this.getBillsList()
        }
      }, r=> {
        this.setCheckoutData({
              paymentState: 0,
              paymentErrorMessage: r?.response?.data?.message,
            });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.P-checkout-body {
  border-top: 1px solid $neutral-color;
}

.P-checkout-body {
  width: 100%;
}

.P-box {
  width: 50px;
  height: 50px;
  background-color: #0a3c68;
}

.P-checkout-body-right {
  width: 310px;
  padding: 5px 15px 15px;
  border-left: 1px solid $neutral-color;
  flex-direction: column;
  .P-next-btn {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
}

.P-checkout-body-left {
  width: calc(100% - 310px);
}

.P-checkout-footer {
  padding: 15px;
  border-top: 1px solid $neutral-color;
}

.P-checkout-container {
  min-height: 500px;
  padding: 5px 15px 15px 15px;
}

.P-cancel-button {
  max-width: 100px;
}

.border-top {
  border-top: 1px solid $neutral-color;
  padding-top: 1px;
}

.P-shipping-steps {
  color: $neutral-color;
}

.P-shipping-dot {
  font-size: 19px;
  margin-right: 2px;
  cursor: pointer;
  color: $neutral-color;

  &:hover {
    color: $primary-color;
  }
}

.P-shipping-dot-active {
  color: $primary-color;
}

</style>

<style lang="scss">


  .P-checkout-block {
    .P-modal-component-header {
      min-height: 30px !important;
    }
  }
</style>
