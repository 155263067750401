<template>
    <div class="P-balance-and-credit-history">
        <ul class="P-order-list">
            <li class="G-justify-between G-align-center">
                <div class="P-pay-from-card">
                    <Span>Pay From Card</Span>
                    <div class="P-card-number G-align-center">
                        <div class="master-card"></div>
                        <span>**** </span>
                        <span>1234</span>
                    </div>
                </div>
                <span>{{ checkoutClients.orderSummary.payFromCard }}</span>
            </li>
        </ul>
        <div class="P-divider"></div>
        <v-expansion-panels class="G-accordion">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <title-component title-text="Balance History" />
                    <template v-slot:actions>
                        <span class="mdi mdi-menu-up P-accordion-plus"/>
                        <span class="mdi mdi-menu-down P-accordion-minus"/>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ul class="P-order-list">
                        <li class="G-justify-between">
                            <span>Initial State</span>
                            <span>{{ checkoutClients.orderSummary.balanceHistory.InitialState }}</span>
                        </li>
                        <li>
                            <span>New Credit</span>
                        </li>
                        <li class="G-justify-between">
                            <span>Full Order Payment</span>
                            <span>{{ checkoutClients.orderSummary.balanceHistory.fullOrderPayment }}</span>
                        </li>
                        <li class="G-justify-between">
                            <span>After Confirm State</span>
                            <span>{{ checkoutClients.orderSummary.balanceHistory.afterConfirmState }}</span>
                        </li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div class="P-divider"></div>
        <v-expansion-panels class="G-accordion">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <title-component title-text="Credit History" />
                    <template v-slot:actions>
                        <span class="mdi mdi-menu-up P-accordion-plus"/>
                        <span class="mdi mdi-menu-down P-accordion-minus"/>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ul class="P-order-list">
                        <li class="G-justify-between G-align-center">
                            <div class="G-flex-column">
                                <span>Initial State</span>
                                <span class="P-up-to-text">{{ checkoutClients.orderSummary.creditHistory.InitialStateUpTo }}</span>
                            </div>
                            <span>{{ checkoutClients.orderSummary.creditHistory.InitialState }}</span>
                        </li>
                        <li class="G-justify-between G-align-center">
                            <div class="G-flex-column">
                                <span>New Credit</span>
                                <span class="P-up-to-text">{{ checkoutClients.orderSummary.creditHistory.newCreditUpTo }}</span>
                            </div>
                            <span>{{ checkoutClients.orderSummary.creditHistory.newCredit }}</span>
                        </li>
                        <li class="G-justify-between">
                            <span>After Confirm State</span>
                            <span>$500</span>
                        </li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div class="P-divider"></div>
    </div>
</template>

<script>
    import TitleComponent from "@/components/TitleComponent";
    import {mapState} from "vuex";
    export default {
        name: "BalanceAndCreditHistory",
        components: {TitleComponent},
        computed: {
            ...mapState({
                checkoutClients: state => state.checkoutClients
            })
        }
    }
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "./orderSummary.scss";
.P-balance-and-credit-history {
    .P-card-number {
        font-size: $txt12;
        color: $neutral-color;
        margin-top: 4px;
        .master-card {
            background: url(/creditCard/master.svg) no-repeat;
            background-size: 20px 20px;
            width: 22px;
            height: 16px;
        }
        span {
            display: block;
            margin: 0 4px;
        }
    }
    .G-accordion {
        .v-expansion-panel-header {
            padding: 0;
        }
        .P-modal-component-header {
            margin-bottom: 0;
            min-height: 26px;
        }
        .v-expansion-panel {
            background: transparent;
        }
        .P-up-to-text {
            font-size: $txt12;
            color: $neutral-color;
        }
    }
}
::v-deep {
    .G-accordion {
        .v-expansion-panel-content__wrap {
            padding: 0;
        }
        .v-expansion-panel-header--active {
            border-bottom: none;
        }
        .v-expansion-panel-header__icon {
            font-size: 24px;
            color: $neutral-color;
        }
    }
    .v-expansion-panel {
        box-shadow: none;
    }
}
</style>