<template>
  <div class="P-payment-step">
    <div class="G-justify-between">
      <title-component title-text="Payment Methods" />
      <title-component :title-text="billNumber" />
    </div>

    <div class="G-justify-between">
      <title-component title-text="Amount" />
      <div class="P-padding-input">
        <OttInput
            :readonly="true"
            v-model="invoiceData.totalAmount.toFixed(2)"
            :is-percent="true"
            :customInputClass="'P-number'"
            label="Amount"
        />
      </div>
    </div>
    <AddCredit />

    <PaymentListCheckout ref="paymentListCheckout" />
  </div>
</template>
<script>
import TitleComponent from "@/components/TitleComponent";
import AddCredit from "./AddCrerdit";
import PaymentListCheckout from "./PaymentListCheckout";
import OttInput from "@/components/vuetifyComponents/OttInput";
import { mapState } from 'vuex';
export default {
  name: "PaymentStep",
  components: { PaymentListCheckout, AddCredit, TitleComponent, OttInput },
  computed: {
    ...mapState({
      billNumber: state => state.clientsModule.billNumber,
      invoiceData: state =>state.checkoutClients.invoiceData,
    })
  },
  mounted(){
    console.log(this.invoiceData)
  },
  data() {
    return {
      amount: 10,
    }
  }
};
</script>