<template>
  <div class="P-payment-list-block">
    <div class="P-payment-list-header">
      <template v-for="(items, index) of paymentList">
        <div v-if="isPaymentAllowed(items.type, invoiceData)" :key="index" @click="setData({ activePaymentType: items.type })" class="P-padding-box">
          <div 
            class="P-payment-types" 
            :class="{
              'P-active-type': activeTab === items.type,
              'light': !$vuetify.theme.dark,
              'dark': $vuetify.theme.dark
            }">
            <span class="P-payment-icon" :class="items.className"/>
            <p class="text-center">{{items.name}}</p>
          </div>
        </div>
      </template>
    </div>
    <div class="P-payment-list-body">
      <PaymentList v-if="activeTab === 'saved'" :isCheckout="true" />
      <CreditCard v-if="activeTab === 'card'" :isCheckout="true"  ref="creditCard" />
      <BanckAccount v-if="activeTab === 'bankTransfer'" :isCheckout="true"  ref="bankAccount" />
      <Cash v-if="activeTab === 'cash'" ref="cash" />
      <CheckMoneyOrder v-if="activeTab === 'check'" :isCheck="true" ref="check" />
      <CheckMoneyOrder v-if="activeTab === 'moneyOrder'" :isMoneyOrder="true" ref="moneyOrder" />
      <FastCredit v-if="activeTab === 'fastCredit'" ref="fastCredit" />
    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions } from "vuex";
  import PaymentList from "./PaymentList";
  import CreditCard from "@/views/clients/modals/manageClient/paymentMethods/components/CreditCard";
  import BanckAccount from "@/views/clients/modals/manageClient/paymentMethods/components/BankAccount";
  import FastCredit from "./FastCredit";
  import Cash from './Cash';
  import CheckMoneyOrder from './CheckMoneyOrder';
  import OttInput from "@/components/vuetifyComponents/OttInput";

  export default {
    name: 'PaymentListCheckout',
    components: {PaymentList, CreditCard, BanckAccount, FastCredit, Cash, CheckMoneyOrder, OttInput},
    data() {
      return {
        amount: 0,
        paymentList: [
          {
            name: 'Saved payment',
            className: 'icon-checked',
            value: 0,
            type: 'saved',
          },
          {
            name: 'Credit card',
            className: 'icon-credit-card',
            value: 1,
            type: 'card',
          },
          {
            name: 'Bank Account',
            className: 'icon-bank-account',
            value: 2,
            type: 'bankTransfer',
          },
          {
            name: 'Cash',
            className: 'icon-cash',
            value: 4,
            type: 'cash',
          },
          {
            name: 'Check',
            className: 'icon-check',
            value: 3,
            type: 'check',
          },
          {
            name: 'Money Order',
            className: 'icon-check',
            value: 5,
            type: 'moneyOrder', //moneyOrder
          },
          {
            name: 'Fast Credit',
            className: 'icon-cash',
            value: 6,
            type: 'fastCredit',
          }
        ],
      }
    },

    computed: {
      ...mapState({
        activeTab: state => state.checkoutClients.activePaymentType,
        invoiceData: state => state.checkoutClients.invoiceData,
      })
    },

    mounted() {
      this.filterAllowedPaymentMethods();
    },
    methods: {
      ...mapMutations({
        setData: "checkoutClients/setData"
      }),
      ...mapActions({
        updatePayData: "checkoutClients/updatePayData",
      }),
      filterAllowedPaymentMethods() {
        const allowedList = this.paymentList.filter(r => this.isPaymentAllowed(r.type, this.invoiceData));
        if (!allowedList.length) {
          console.warn(`no allowed payment type...`)
          return;
        }
        if (!allowedList.filter(r=>r.type === this.activeTab).length) {
          this.setData({ activePaymentType: allowedList[0].type })
        }
      },
      isPaymentAllowed(type, invoiceData) {
        return invoiceData?.generateDisplayInfo?.availablePaymentTypes && typeof invoiceData?.generateDisplayInfo?.availablePaymentTypes[type] !== 'undefined'
      }
    },
    watch: {
      invoiceData(data) {
        this.filterAllowedPaymentMethods();
      },

      activeTab(newVal, oldVal) {
        const checkList = ['card', 'saved'];
        if (oldVal !== newVal) {
          const oldCointains = checkList.includes(oldVal);
          const newContains = checkList.includes(newVal);
          if (oldCointains !== newContains)  {
            this.updatePayData({
            paymentMethod: newVal
          }).then(r=>{
            console.log(`updatePaymentData data arrived`);
          }).catch(r=>{});
        }
      }
      }
    }
  }
</script>
<style scoped lang="scss">
  @import "src/assets/scss/variables";

  .P-padding-box {
    padding : 10px;
    width   : 16.66%;

    .P-payment-types {
      border-radius    : 4px;
      box-shadow       : 0 0 5px rgba(0, 0, 0, 0.07);
      padding          : 14px;
      display          : flex;
      flex-direction   : column;
      align-items      : center;
      justify-content  : center;
      cursor           : pointer;
      transition       : $transition;
      border           : 1px solid transparent;

      span {
        width         : 23px;
        height        : 23px;
        display       : block;
        font-size     : 26px;
        margin-bottom : 10px;
        transition    : $transition;

        &:before {
          width           : 100%;
          height          : 100%;
          display         : flex;
          align-items     : center;
          justify-content : center;
        }
      }

      p {
        font-size   : $txt14;
        font-weight : 600;
        margin      : 0;
        transition  : $transition;
      }

      &.P-active-type {
        border-color : $primary-color;

        span, p {
          color : $primary-color !important;
        }
      }
    }

    .P-payment-types.light {
      background-color: $white;

      & span {
        color: $neutral-color;
      }

      & p {
        color: $secondary-color;
      }
    }

    .P-payment-types.dark {
      background-color : rgba(255, 255, 255, 0.07);

      & span {
        color: white;
      }

      & p {
        color: $white;
      }
    }
  }

  .P-payment-list-header {
    display   : flex;
    flex-wrap : wrap;
    margin    : 0 -10px;
  }

  .P-payment-list-block {
    margin-top : 10px;
  }

  .P-payment-list-body {
    min-height : 300px;
  }
</style>