<template>
  <div class="P-manage-balance">
    <div class="P-balance-component G-flex" v-if="!checkoutClients.isAddCredit">
      <div class="P-line"></div>
      <div class="P-manage-balance-box">
        <title-component title-text="Balance" />
        <p class="P-sub-title neutral--text">
          You can pay off the debt in part or in full through a refund.
        </p>
        <h3 class="P-balance"><i>$</i>{{ balance }}</h3>
        <div v-if="!credit.creditAmount" class="P-add-credit P-no-credit G-justify-between G-align-center">
          <div class="P-add-credit-info G-align-center">
            <p class="P-status-credit">No credit</p>
          </div>
          <span
            class="mdi mdi-plus P-add-button"
            @click="openManageCreditData"
          />
        </div>
      </div>
      <div class="P-manage-balance-box P-manage-client">
        <title-component title-text="Pay From Balance" />
        <div class="P-input">
          <ott-input
            v-model.number="payFromBalance"
            type="number"
            :min="0.1"
            label="Pay From Balance"
          />
        </div>
        <SaveResetButtons
          :is-loading="isLoading"
          :left-click-func="() => $emit('closeBalanceCredit')"
          :right-click-func="payFromBalanceFunc"
          :right-label="balance ? `Pay` : `Insufficient funds` "
        />
      </div>
    </div>
    <div class="P-balance-component G-flex" v-else>
      <div class="P-manage-balance-box P-manage-client">
        <TitleComponent title-text="Add Credit" class="mb-2" />

        <ott-switch
          v-model="isStartNow"
          className="secondaryColor"
          label="Start Now"
        />

        <div v-if="!isStartNow" class="P-date-picker mt-4">
          <DatePicker
            v-model="creditStartDate"
            :masks="{ input: ottDateFormat }"
            :popover="{ visibility: 'click' }"
            :min-date="newDateWithMainUserTZ"
            :is-dark="$vuetify.theme.dark"
            locale="en"
            class="ott-date-picker"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="ott-date-picker-cont">
                <!-- :disabled="credit.isEdit"
                :error="$v.credit.date.$error" -->
                <v-text-field
                  v-model="inputValue"
                  v-on="inputEvents"
                  :readonly="true"
                  :error="$v.credit.date.$error"
                  outlined
                  dense
                  height="38px"
                  label="Credit Start Date*"
                  append-icon="mdi mdi-calendar-blank-outline neutral--text"
                />
              </div>
            </template>
          </DatePicker>
        </div>

        <div class="P-input-block">
          <OttInput
            v-model="creditAmount"
            :error="$v.credit.creditAmount.$error"
            :is-percent="true"
            :customInputClass="'P-amount-code'"
            label="Credit Amount "
          />
        </div>
        <div class="P-input-block">
          <v-radio-group v-model="radioValue" :error="$v.credit.radioValue.$error" row>
            <ott-radio v-model="credit.radio[0]" :label="credit.radio[0]" />
            <ott-radio v-model="credit.radio[1]" :label="credit.radio[1]" />
          </v-radio-group>
        </div>
        <div class="P-input-block">
          <ott-select
            v-model="creditTerm"
            :error="$v.credit.selectedCreditTerm.$error"
            :items="radioValue === credit.radio[0] ? daysList : monthsList"
            :label="radioValue === credit.radio[0] ? 'Day' : 'Month'"
            :multiple="false"
            :persistentHint="true"
            selected-text="value"
          />
        </div>
        <div class="P-add-credit-button">
          <save-reset-buttons
            :is-loading="isLoading"
            :right-click-func="() => startCredit()"
            :left-click-func="() => cancelAddCredit()"
            right-label="Pay"
          />
        </div>
      </div>
      <div class="P-manage-balance-box P-manage-client">
        <TitleComponent title-text="Bill Send" />
        <div class="P-checkbox">
          <ott-checkbox label="By Email ( Emailexample@l.com )" />
          <ott-checkbox label="By Postal method API" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import { eventBus } from "@/main";

import DateFormatMixin from "@/mixins/DateFormatMixin";
import DaysMonthMixin from "@/mixins/DaysMonthMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import TitleComponent from "@/components/TitleComponent";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttInput from "@/components/vuetifyComponents/OttInput";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import DatePicker from "v-calendar/src/components/DatePicker";

export default {
  name: "ManageBalanceCredit",
  components: {
    TitleComponent,
    OttSwitch,
    OttCheckbox,
    OttSelect,
    OttRadio,
    SaveResetButtons,
    OttInput,
    DatePicker,
  },

  validations: {
    credit: {
      creditAmount: { required },
      selectedCreditTerm: { required },
      radioValue: { required },
      date: {
        required: requiredIf(function () {
          return !this.isStartNow;
        }),
      },
    },

  },
  mixins: [DateFormatMixin, DaysMonthMixin, ConvertTimeZoneMixin],

  computed: {
    ...mapState({
      checkoutClients: (state) => state.checkoutClients,
      balance: (state) => state.balanceCreditClient.mainBalance,
      credit: (state) => state.balanceCreditClient.credit,
      clientId: (state) => state.clientsModule.clientId,
      invoiceData: (state) => state.checkoutClients.invoiceData,
      isLoading: (state) => state.appModule.isLoading,
    }),

    payFromBalance: {
      set(payFromBalance) {
        this.setData({ payFromBalance });
      },

      get() {
        return this.checkoutClients.payFromBalance;
      },
    },

    isStartNow: {
      get() {
        return this.credit.isStartNow;
      },
      set(isStartNow) {
        this.setBalanceCreditSecondLevels({
          credit: { isStartNow },
        });
      },
    },

    creditStartDate: {
      get() {
        return this.credit.date;
      },
      set(date) {
        this.setBalanceCreditSecondLevels({
          credit: { date },
        });
      },
    },

    creditAmount: {
      get() {
        return this.credit.creditAmount;
      },
      set(creditAmount) {
        this.setBalanceCreditSecondLevels({
          credit: { creditAmount },
        });
      },
    },

    radioValue: {
      get() {
        return this.credit.radioValue;
      },
      set(radioValue) {
        this.setBalanceCreditSecondLevels({
          credit: { radioValue },
        });
      },
    },

    creditTerm: {
      get() {
        return this.credit.selectedCreditTerm;
      },
      set(selectedCreditTerm) {
        this.setBalanceCreditSecondLevels({
          credit: { selectedCreditTerm },
        });
      },
    },
  },

 mounted() {
    if (this.balance > 0) {
      if (this.balance >= this.invoiceData.amount) {
        this.payFromBalance = this.invoiceData.totalAmount.toFixed(2)
      } else {
        this.payFromBalance = this.balance
      }
    }
 },

  methods: {
    ...mapActions({
      addCredit: "balanceCreditClient/addCredit",
      balanceCreditClient: "balanceCreditClient/getBalanceCredit",
      payInvoice: "checkoutClients/payInvoice"
    }),

    ...mapMutations({
      setData: "checkoutClients/setData",
      toggleSetCreditOpen: "checkoutClients/toggleSetCreditOpen",
      setBalanceCreditSecondLevels: "balanceCreditClient/setDataSecondLevels",
      setCreditIsStart: 'balanceCreditClient/setIsStart',
      showBalance: 'balanceCreditClient/saveBalance',
      resetBalanceManage: 'balanceCreditClient/resetBalanceManage',
    }),

    /**
     * Manage Balance data
     * Pay or Refund
     * **/

    payFromBalanceFunc() {
      this.payInvoice({ balance: { amount: this.payFromBalance }}).then((data) => {
        console.log(data)
      }).catch((error) => {
        console.error(error)
      })
      // eventBus.$emit("payFromBalance");
    },

    /**
     * add credit Data
     * **/
    async startCredit() {
      this.$v.credit.$touch();
      if (!this.$v.credit.$error) {
        const data = {
          clientId: this.clientId,
          startNow: this.credit.isStartNow,
          creditAmount: this.credit.creditAmount,
          creditStartDate: this.credit.isStartNow
            ? new Date()
            : this.credit.date,
          creditComment: this.credit.comment,
          creditTerm: this.credit.selectedCreditTerm,
          days: this.credit.radioValue === "Day",
          months: this.credit.radioValue === "Month",
          clientsPauseAfterDays:
            this.credit.radioValue === "Day"
              ? this.credit.selectedCreditTerm
              : "",
          creditAutoextend: this.credit.creditAutoextend,
        };

        await this.addCredit(data)
        await this.balanceCreditClient()

        if (this.credit.creditAmount) {
          this.cancelAddCredit()
        }
      }
    },
    /**
     * cancel add credit
     * **/

    cancelAddCredit() {
      this.$v.credit.$reset();
      this.setBalanceCreditSecondLevels({
        credit: {
          isStartNow: false,
          isStart: false,
          isEdit: false,
          selectedCreditTerm: null,
          date: null,
          radioValue: "Day",
          radio: ["Day", "Month"],
        },
      }),
      this.toggleSetCreditOpen(false);
    },

    /**
     * open manage credit data
     * **/

    openManageCreditData() {
      this.toggleSetCreditOpen(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/datePicker";

.P-manage-balance-box {
  width: 50%;
  padding-right: 25px;

  &:last-child {
    padding-left: 25px;
    padding-right: 0;
  }
}

.P-line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: $neutral-color45;
}

.P-sub-title {
  font-size: $txt12;
}

.P-manage-balance-box::v-deep {
  .P-modal-component-header {
    margin-bottom: 0;
    min-height: unset;
  }
}

.P-balance {
  color: $secondary-color;
  font-size: 36px;
  display: flex;
  align-items: flex-start;
  line-height: 1;
  font-weight: 600;

  i {
    font-style: unset;
    font-size: $txt14;
    color: $neutral-color;
    margin-top: 5px;
    margin-right: 3px;
  }
}

.P-add-credit {
  margin-top: 15px;
  width: 100%;
  border-radius: 4px;

  &.P-no-credit {
    background-color: $green-new-light;

    .P-add-credit-info {
      p {
        &:before {
          background-color: $green-new;
        }
      }
    }

    .P-add-button {
      color: $green-new;

      &:hover {
        background-color: $green-new;
        color: $white;
      }
    }
  }

  &.P-has-credit {
    background-color: $danger-new-light;

    .P-add-credit-info {
      p {
        color: $danger-new;

        &:before {
          background-color: $danger-new;
        }
      }
    }

    .P-add-button {
      color: $danger-new;

      &:hover {
        background-color: $danger-new;
        color: $white;
      }
    }
  }

  .P-add-credit-info {
    padding: 0 10px;

    p {
      margin: 0;
      font-size: $txt12;
      font-weight: 600;
      color: $green-new;
      position: relative;
      padding-left: 15px;

      &:before {
        width: 8px;
        height: 8px;
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }
  }
}

.P-add-button {
  width: 29px;
  height: 29px;
  border-radius: 4px;
  font-size: $txt20;
  display: block;
  transition: $transition;
  cursor: pointer;

  &:before {
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.P-input {
  margin: 20px 0 25px 0;
}

.P-manage-balance-box::v-deep {
  .P-save-and-reset-block {
    margin-top: 0;
  }
}

.P-manage-client::v-deep {
  .v-text-field__details,
  .v-input__slot {
    margin-bottom: 0;
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 0;
  }

  .P-checkbox {
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}

.P-input-block {
  margin: 15px 0;
}

.P-input-block::v-deep {
  .v-menu__content {
    max-height: 150px !important;
  }
}

.P-add-credit-button {
  margin-top: 25px;
}
.P-checkbox {
  margin-top: 10px;
}
</style>
