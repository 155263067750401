<template>
  <div class="P-addresses-step">
    <div 
      :class="{
        'P-dashed-border': !isOpenFromForm && !isOpenToForm,
        'P-solid-border' : isOpenFromForm || (!isOpenFromForm && isOpenToForm)
      }" 
      class="ps-5 mt-4 P-forms-cont p-relative"
    >
      <!-- Ship From Circel -->
      <div
        :class="{
          'P-empty-circle': !shipFromAddress,
          'P-colored-circle': shipFromAddress
        }"
        class="P-circle P-top-circle d-flex align-center justify-content-center"
      />
      <!---------------------->

      <div>
        <div class="P-ship-from d-flex align-center w-max-content mb-2">
          <title-component 
            :click="openFromForm"
            :title-text="shipFromText" 
            :isEdit="shipFromAddress ? true : false"
            :isAdd="!shipFromAddress && !isOpenFromForm ? true : false"
          />

          <div v-if="isOpenFromForm" @click="isOpenFromForm = false" class="icon-cont">
            <span class="mdi mdi-chevron-up" />
          </div>
        </div>

        <transition name="slide">
          <ManageAddress v-if="isOpenFromForm" :ship-from="true" />
        </transition>
      </div>

      <div class="mt-4">
        <div class="P-ship-to w-max-content d-flex p-relative align-center mt-5">
          <!-- Ship To Circel -->
          <div
            :class="{
              'P-empty-circle': !shipToAddress,
              'P-colored-circle': shipToAddress
            }"
            class="P-circle P-bottom-circle d-flex align-center justify-content-center"
          />
          <!-------------------->

          <title-component
            :click="openToForm"
            :title-text="shipToText" 
            :isEdit="shipToAddress ? true : false"
            :isAdd="!shipToAddress && shipFromAddress && !isOpenToForm ? true : false"
          />

          <div v-if="isOpenToForm" @click="isOpenToForm = false" class="icon-cont">
            <span class="mdi mdi-chevron-up" />
          </div>
        </div>

        <transition name="slide">
          <ManageAddress v-if="isOpenToForm" :ship-to="true" />
        </transition>
      </div>
    </div> 
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import TitleComponent from "@/components/TitleComponent";
import ManageAddress from "./ManageAddress"

export default {
  name: "AddressesStep",
  components: { TitleComponent, ManageAddress },
  computed: {
    ...mapState({
      invoiceData: state => state.checkoutClients.invoiceData,
      shipFromAddress: state => state.shippingClients.shipFromAddress,
      shipFromFormData: state => state.shippingClients.shipFromFormData,
      shipToAddress: state => state.shippingClients.shipToAddress,
      shipToFormData: state => state.shippingClients.shipToFormData,
      isOpenFrom: state => state.shippingClients.isOpenFromForm,
      isOpenTo: state => state.shippingClients.isOpenToForm,
    }),

    isOpenFromForm: {
      set(isOpenFromForm) {
        this.setData({ isOpenFromForm })
      },
      get() {
        return this.isOpenFrom
      }
    },
    
    isOpenToForm: {
      set(isOpenToForm) {
        this.setData({ isOpenToForm })
      },
      get() { 
        return this.isOpenTo
      }
    },

    shipFromText() {
      if (this.shipFromAddress) {
        return this.shipFromAddress
      }

      return "Ship From"
    },

    shipToText() {
      if (this.shipToAddress) {
        return this.shipToAddress
      }

      return "Ship To"
    }
  },
  methods: {
    ...mapMutations({
      setData: 'shippingClients/setData'
    }),

    openFromForm() {
      this.isOpenFromForm = true
      this.isOpenToForm = false
    },

    openToForm() {
      this.isOpenToForm = true
      this.isOpenFromForm = false
    },
  }
};
</script>

<style scoped lang="scss">
  @import "src/assets/scss/variables";

  .P-dashed-border {
    border-left: 1px dashed $neutral-color;
  }

  .P-solid-border {
    border-left: 1px solid $neutral-color;
  }

  .P-forms-cont {
    & .P-ship-from, & .P-ship-to {
      height: 16px;
    }
  }

  .P-top-circle {
    top: 0;
    left: -7.5px;
  }

  .P-bottom-circle {
    bottom: 0;
    left: -27.5px;
  }
  
  .P-circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
  }

  .P-empty-circle {
    border: 1px solid $neutral-color;
    background-color: white;
  }

  .P-colored-circle {
    border: 4px solid $primary-color;
    background-color: white;
  }

  .p-relative {
    position: relative;
  }
</style>

<style lang="scss">
  .P-addresses-step {
    .icon-cont {
      margin-left: 25px !important;
    }

    .P-modal-component-header {
      margin-bottom: 0 !important;
    }
  }
</style>