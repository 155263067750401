<template>
  <div class="P-information-step">
    <div class="d-flex mt-3">
      <div class="d-flex flex-column w-100">
        <div class="d-flex align-center w-100">
          <div class="P-padding-50 ps-0">
            <ott-select
              v-model="selectedBox"
              :multiple="false"
              :persistentHint="true"
              :items="easyShipBoxItems"
              :clear="true"
              :error="showShipBoxErrors && !selectedBox"
              @emitFunc="selectBox($event)"
              @emmitClearData="clearSelect($event, index)"
              label="Box Name"
              selected-text="name"
            />
          </div>

          <div class="P-padding-50 d-flex align-center">
            <ott-input
              v-model.number="boxLength"
              :disabled="selectedBox !== 'custom'"
              :error="showShipBoxErrors && !boxLength"
              :min="0.01"
              type="number"
              label="L"
            />

            <span class="mx-2 P-label">x</span>

            <ott-input
              v-model.number="boxWidth"
              :disabled="selectedBox !== 'custom'"
              :error="showShipBoxErrors && !boxWidth"
              :min="0.01"
              type="number"
              label="W"
            />
            <span class="mx-2 P-label">x</span>

            <ott-input
              v-model.number="boxHeight"
              :disabled="selectedBox !== 'custom'"
              :error="showShipBoxErrors && !boxHeight"
              :min="0.01"
              type="number"
              label="H"
            />
          </div>

          <div class="P-padding-15 pe-0">
            <ott-input
              v-model.number="boxWeight"
              :error="showShipBoxErrors && !boxWeight"
              :min="0.01"
              type="number"
              label="Weight lb"
            />
          </div>

          <div
            v-if="shipInfoBoxesData.length > 1 && index"
            @click="deleteBoxForm(index)"
            class="mdi mdi-delete-outline delete-icon"
          ></div>
        </div>
      </div>
    </div>

    <div class="P-table-list mt-6">
      <table v-if="groupedEquipmentsList.length" class="w-100 text-center">
        <tr>
          <th
            v-for="(header, index) of equipmentsHeaders"
            :key="index"
            :class="{ 'border-0': index === equipmentsHeaders.length - 1 }"
            class="secondary--text"
          >
            {{ header }}
          </th>
        </tr>

        <template v-for="(item, index) of groupedEquipmentsList">
          <tr v-show="!item.id" :key="index" class="P-hovered-tr">
            <td class="secondary--text">
              {{ getName(item.equipment) }}
            </td>
            <td class="secondary--text">
              {{ getName(item.equipment.type) }}
            </td>
            <td class="secondary--text w-30 text-wrap px-3" v-html="getEquipmentDescription(item.equipment.id)" />
            <td class="secondary--text">
              {{ 
                typeof item.price === 'number'
                  ? numberToFinanceNumber(item.price)
                  : "-" 
              }}
            </td>
            <td class="secondary--text">{{ item.qty }}</td>
            <td class="secondary--text border-0">{{ 
              typeof item.totalPrice === 'number'
                  ? numberToFinanceNumber(item.totalPrice )
                  : "-"
            }}</td>
          </tr>
        </template>
      </table>
    </div>

    <!--    <div v-if="hasError"><span>{{ errorMessage }}</span></div>-->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import _ from "lodash";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";

export default {
  name: "InformationStep",
  components: {
    OttSwitch,
    OttInput,
    OttSelect,
  },

  computed: {
    ...mapState({
      invoiceData: (state) => state.checkoutClients.invoiceData,
      equipments: (state) => state.equipments,
      easyShipAllBoxes: (state) => state.shippingClients.easyShipAllBoxes,
      easyShipBoxItems: (state) => state.shippingClients.easyShipBoxItems,
      showShipBoxErrors: (state) => state.shippingClients.showShipBoxErrors,
      shipInfoBoxesData: (state) => state.shippingClients.shipInfoBoxesData,
      groupedEquipmentsList: (state) =>
        state.shippingClients.groupedEquipmentsList,
      hasError: (state) => state.shippingClients.hasError,
      errorMessage: (state) => state.shippingClients.errorMessage,
    }),

    selectedBox: {
      set(value) {
        this.setShipBoxesDataKeys({
          key: "box",
          value: value,
        });
      },
      get() {
        return this.shipInfoBoxesData[0].box;
      },
    },

    boxHeight: {
      set(value) {
        this.setShipBoxesDataKeys({
          key: "height",
          value: this.hasTwoOrMoreDecimals(value) ? value.toFixed(2) : value,
        });
      },
      get() {
        return this.shipInfoBoxesData[0].height;
      },
    },
    boxWidth: {
      set(value) {
        this.setShipBoxesDataKeys({
          key: "width",
          value: this.hasTwoOrMoreDecimals(value) ? value.toFixed(2) : value,
        });
      },
      get() {
        return this.shipInfoBoxesData[0].width;
      },
    },
    boxLength: {
      set(value) {
        this.setShipBoxesDataKeys({
          key: "length",
          value: this.hasTwoOrMoreDecimals(value) ? value.toFixed(2) : value,
        });
      },
      get() {
        return this.shipInfoBoxesData[0].length;
      },
    },
    boxWeight: {
      set(value) {
        this.setShipBoxesDataKeys({ 
          key: "weight", 
          value: this.hasTwoOrMoreDecimals(value) ? value.toFixed(2) : value,
        });
      },
      get() {
        return this.shipInfoBoxesData[0].weight;
      },
    },

    maxWidth() {
      let groupedEquipmentsList = [];
      const list = [];
      let width = 0;

      if (this.groupedEquipmentsList.length) {
        groupedEquipmentsList = this.groupedEquipmentsList.filter((item) => !item.id)
        groupedEquipmentsList.map((item) => {
          width = item.equipment.information.width;
          if (item.equipment.information.unitSize === "cm") {
            list.push(width * 0.3937); // cm to in
          } else {
            list.push(width);
          }
        });
      }

      if (list.length) {
        return Math.max(...list);
      }
      return width;
    },

    maxHeight() {
      let groupedEquipmentsList = [];
      const list = [];
      let height = 0;

      if (this.groupedEquipmentsList.length) {
        groupedEquipmentsList = this.groupedEquipmentsList.filter((item) => !item.id)
        groupedEquipmentsList.map((item) => {
          height = item.equipment.information.height;
          if (item.equipment.information.unitSize === "cm") {
            list.push(height * 0.3937); // cm to in
          } else {
            list.push(height);
          }
        });
      }

      if (list.length) {
        return Math.max(...list);
      }
      return height;
    },

    maxLength() {
      let groupedEquipmentsList = [];
      const list = [];

      let length = 0;

      if (this.groupedEquipmentsList.length) {
        groupedEquipmentsList = this.groupedEquipmentsList.filter((item) => !item.id)
        groupedEquipmentsList.map((item) => {
          length = item.equipment.information.length;
          if (item.equipment.information.unitSize === "cm") {
            list.push(length * 0.3937); // cm to in
          } else {
            list.push(length);
          }
        });
      }

      if (list.length) {
        return Math.max(...list);
      }
      return length;
    },

    maxWeight() {
      let groupedEquipmentsList = [];
      const list = [];
      let weight = 0;

      if (this.groupedEquipmentsList.length) {
        groupedEquipmentsList = this.groupedEquipmentsList.filter((item) => !item.id)
        groupedEquipmentsList.map((item) => {
          weight += item.equipment.information.productWeight;
          if (item.equipment.information.unitWeight === "kg") {
            list.push(weight * 2.2046); // kg to lb
          } else {
            list.push(weight);
          }
        });
      }

      if (list.length) {
        return Math.max(...list);
      }
      return weight;
    },

    equipmentsList() {
      let equipments = [];
      if (this.equipments.clientEquipments.length) {
        equipments = this.equipments.clientEquipments.filter(
          (item) => item.equipment
        );
      }

      return equipments;
    },
  },

  data() {
    return {
      equipmentsHeaders: [
        "Name",
        "Type",
        "Description",
        "Unit Price",
        "Qty",
        "Total Price",
      ],
    };
  },

  async created() {
    await this.getAllEasyshipBoxes();
  },

  mounted() {
    this.getGroupedEquipmentsList();

    if (this.maxHeight && this.maxWidth && this.maxLength && this.maxWeight) {
      this.selectedBox = "custom";
      this.boxHeight = this.maxHeight;
      this.boxWidth = this.maxWidth;
      this.boxLength = this.maxLength;
      this.boxWeight = this.maxWeight;
    }
  },

  destroyed() {
    this.setData({
      showShipBoxErrors: false,
    });
  },

  methods: {
    ...mapMutations({
      equipmentsSetData: "equipments/setData",
      setData: "shippingClients/setData",
      setShipBoxesDataKeys: "shippingClients/setShipBoxesDataKeys",
    }),

    ...mapActions({
      getAllEasyshipBoxes: "shippingClients/getAllEasyShipBoxes",
    }),

    numberToFinanceNumber(number) {
      const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(number);

      if (number === 0) {
        return '$0.00'
      } else {
        return formattedNumber;
      }
    },

    hasTwoOrMoreDecimals(num) {
      return (
        Number(num) === num &&
        num % 1 !== 0 &&
        num.toString().split(".")[1].length >= 2
      );
    },

    selectBox(boxId) {
      if (boxId) {
        if (boxId !== "custom") {
          const box = this.easyShipAllBoxes.filter(
            (item) => item.id === boxId
          )[0];
          this.boxWidth = box.outer_width;
          this.boxHeight = box.outer_height;
          this.boxLength = box.outer_length;
        } else {
          this.boxWidth = null;
          this.boxHeight = null;
          this.boxLength = null;
        }
      }
    },

    clearSelect(box, formIndex) {
      this.shipInfoBoxesData[formIndex].width = null;
      this.shipInfoBoxesData[formIndex].height = null;
      this.shipInfoBoxesData[formIndex].length = null;
    },

    deleteBoxForm(index) {
      this.shipInfoBoxesData.splice(index, 1);
    },

    getName(nameObj) {
      if (typeof nameObj === "object") {
        return nameObj.name.map((name) =>
          name.lang === "en" ? name.name : name[0].name
        )[0];
      } else {
        return "-";
      }
    },

    getEquipmentDescription(id) {
      let description = null;

      this.equipments.allEquipments.map((item) => {
        if (item._id === id) {
          description = item.description;
        }
      });

      return description;
    },

    getGroupedEquipmentsList() {
      const equipmentsList = _.clone(this.equipmentsList);
      const groupedItems = {};

      // Loop through each item in the array
      equipmentsList.forEach((item) => {
        // If the item doesn't have an id, group it by the whole object
        const id = item.id ? item.id : JSON.stringify(item);

        // If this is the first time we see this id, initialize the group
        if (!groupedItems[id]) {
          groupedItems[id] = {
            ...item,
            qty: 1,
            totalPrice: item.price,
          };
        } else {
          // Otherwise, increment the quantity and add to the total price
          groupedItems[id].qty++;
          groupedItems[id].totalPrice += item.price;
        }
      });

      // Array of the groups
      this.setData({
        groupedEquipmentsList: Object.values(groupedItems),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.border-0::v-deep {
  border: unset;
}

.P-label {
  font-size: $txt14;
  color: $neutral-color;
}

.delete-icon {
  font-size: 25px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $neutral-color;
  transition: $transition;

  &:hover {
    color: $danger-color-light;
  }
}

.P-table-list {
  overflow: hidden;
  border-radius: 4px;
  margin-top: 12px;
  max-height: 350px;
  overflow-y: auto;
}

.theme--light {
  & th {
    background-color: white;
  }
}

.P-hovered-tr {
  position: relative;

  &:after {
    width: 2px;
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    transition: $transition;
    background-color: $primary-color;
    content: "";
  }

  &:hover {
    &:after {
      height: 100%;
    }

    & td {
      background-color: $neutral-color7;
    }
  }
}

.theme--dark {
  & th {
    background-color: $dark_light;
  }

  .P-hovered-tr {
    &:hover {
      & td {
        background-color: transparent !important;
      }
    }
  }
}

table {
  text-align: center;
  width: 100%;
  border-collapse: collapse;
  tr,
  td,
  th {
    border: 1px solid $neutral-color45;
    text-align: center;
  }

  & tr {
    th {
      font-size: $txt12;
      font-weight: bold;
      padding: 8px;
      vertical-align: middle;
      text-transform: capitalize;
    }

    td {
      font-size: $txt14;
      word-break: break-all;
      padding: 8px;
      transition: $transition;
      vertical-align: middle;
      text-transform: capitalize;

      // & + li {
      //   border-left : 1px solid $neutral-color45;
      // }

      p {
        margin-bottom: 0;
      }

      // &:last-child {
      //   padding: 15px 0;
      //   border : 1px solid transparent;
      // }
    }

    &:hover {
      .P-actions {
        opacity: 1;
        visibility: visible;
      }
      td {
        background-color: $neutral-color7;
      }
    }
  }
}
</style>