<template>
  <div>

  <div>
    <title-component :title-text="isMoneyOrder ? 'Money Order' : 'Check'" />
    <div class="G-justify-between">
      <div class="P-padding-input">
        <OttInput
          v-if="isMoneyOrder"
          v-model="moneyOrderNumber"
          :customInputClass="'P-number mb-3'"
          :error="$v.moneyOrder.number.$error"
          label="Money Order Nunmber"
        />
        <OttInput
          v-if="isCheck"
          v-model="checkNumber"
          :customInputClass="'P-number mb-3'"
          :error="$v.check.number.$error"
          label="Number"
        />
      </div>

      <!-- Bank Search -->
      <div class="bank-search-select P-padding-input">
        <div>
          <ott-input
            v-model="bankName"
            @emitFunc="bankSearch"
            :timeoutInput="true"
            :hideDetails="true"
            label="Bank Name"
            prependInnerIcon="mdi mdi-magnify  neutral--text"
          ></ott-input>
        </div>

        <div
          v-if="showBankSearch && filteredBankNames.length"
          v-click-outside="bankeSearchOutside"
          :class="$vuetify.theme.dark ? 'dark' : 'light'"
          class="bank-search-select__result P-menu-list"
        >
          <v-list>
            <v-list-item
              v-for="(item, key) of filteredBankNames"
              :key="key"
              :ripple="false"
            >
              <div class="list-item G-align-center" @click.stop="bankNameClick(item.name)">
                <v-list-item-title class="G-align-center">{{ item.name }}</v-list-item-title>
              </div>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isCheck && invoiceData?.payloadCalculated.refund">
    <title-component :title-text="`Checkeeper`" />
    <div class="G-justify-between">
      <div class="P-padding-input">
      <ott-select
            v-model="checkeeperMailingValue"
            :items="checkeeperMailingOptions"
            selected-text="name"
            label="Mailing Option"
          />
        </div>
      </div>

      <!-- Bank Search -->
      <div class="bank-search-select P-padding-input">
<div>
      <ott-select
            :items="[{ name: 'Address name here', value: 'address_id'}]"
            selected-text="name"
            label="Client Address select"
          />
        </div>
      </div>

      <!-- <span>${{  invoiceData.payloadCalculated.checkeeper.pdf }}</span> -->

      <!-- <a v-if="this.invoiceData?.payloadCalculated?.checkeeper?.status" target="_blank" :href="`http://localhost:3000/v1/invoices/viewCheckPdf/${invoiceData.id}?type=1`" frameborder="0">View PDF</a> -->
      <!-- {{ invoiceData?.payloadCalculated?.checkeeper }}
      <div class="P-confirm-send G-justify-center" v-if="invoiceData?.payloadCalculated?.checkeeper">
        <div class="P-confirm-send-block flex-column G-flex-center">
          <transition name="fade">
            <img 
              :src="`${process.env.VUE_APP_API_URL}invoices/viewCheckPdf/${this.invoiceData.id}?type=2&jpeg=true`"
              @click="isShowImage = true"
              class="P-invoice-img" 
              alt="Invoice Image"
            />
          </transition>
        </div>
      </div> -->
      <div class="P-confirm-send G-justify-center">
        <div class="P-confirm-send-block flex-column G-flex-center">
          <transition name="fade">
            <!-- <img 
              :src="`http://localhost:3000/v1/invoices/viewCheckPdf/6538cb11ecd97550175c0a78?type=1`"
              @click="isShowImage = true"
              class="P-invoice-img" 
              alt="Invoice Image"
            /> -->
          </transition>
        </div>
      </div>
      
    </div>
  </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import { eventBus } from "@/main";
import TitleComponent from "@/components/TitleComponent";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";

export default {
  name: "CheckMoneyOrder",
  components: {
    TitleComponent,
    OttInput,
    OttSelect,
  },
  validations: {
    check: {
      number: {
        required: requiredIf(function () {
          return this.isCheck;
        }),
      },
    },
    moneyOrder: {
      number: {
        required: requiredIf(function () {
          return this.isMoneyOrder;
        }),
      },
    },
  },
  props: {
    isCheck: {
      type: Boolean,
      default: false,
    },
    isMoneyOrder: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getBankNames()

    eventBus.$on("checkoutNext", async () => {
      await this.addBankName()
    });
    
    this.$hubEventBus.$on("payinvoice-info", this.handleSocketData); // if loggedIn get permissions with WS

  },
  beforeDestroy() {

    this.$hubEventBus.$off("payinvoice-info", this.handleSocketData); // if loggedIn get permissions with WS
  },

  computed: {
    ...mapState({
      invoiceData: (state) => state.checkoutClients.invoiceData,
      check: (state) => state.checkoutClients.check,
      moneyOrder: (state) => state.checkoutClients.moneyOrder,
      bankNameValue: (state) => state.checkoutClients.bankName,
      bankNames: (state) => state.checkoutClients.bankNames
    }),

    checkNumber: {
      get() {
        return this.check.number;
      },
      set(number) {
        this.setDataSecondLevels({
          check: { number },
        });
      },
    },

    moneyOrderNumber: {
      get() {
        return this.moneyOrder.number;
      },
      set(number) {
        this.setDataSecondLevels({
          moneyOrder: { number },
        });
      },
    },

    bankName: {
      get() {
        return this.bankNameValue;
      },
      set(bankName) {
        this.setData({ bankName });
      },
    },
  },

  data() {
    return {
      statusMessage: '',
      showBankSearch: false,
      filteredBankNames: [],
      checkType: '',
      checkeeperMailingValue: null,
      checkeeperMailingOptions: [
        {
        name:'priority',
        value: 'Priority'
      },]
    };
  },

  methods: {
    handleSocketData(info){
      this.statusMessage = info.message;
    },
    invoiceImg() {
      return ``
    },
    ...mapMutations({
      setDataSecondLevels: "checkoutClients/setDataSecondLevels",
      setData: "checkoutClients/setData",
    }),

    ...mapActions({
      addBankNameAction: "checkoutClients/addBankName",
      getBankNames: "checkoutClients/getBankNames"
    }),

    // Bank Search
    bankeSearchOutside() {
      this.showBankSearch = false;
      this.filteredBankNames = [];
    },

    bankSearch() {
      this.showBankSearch = true;
      this.filteredBankNames = this.bankNames.filter((item) =>
        item.name.toLowerCase().includes(this.bankName.trim().toLowerCase())
      );
    },

     async addBankName() {
      // check if typed bank name not duplicated then add
      let isDuplicate = this.bankNames.some(item => item.name === this.bankName.trim());

      if (this.bankName && !isDuplicate) {
        await this.addBankNameAction(this.bankName)
      }

      await this.getBankNames()
     },

    bankNameClick(name) {
      this.bankName = name;
      this.showBankSearch = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.P-padding-input {
  width: 49%;
}

// Bank Search
.bank-search-select {
  position: relative;

  &__input {
    width: 60px;
  }
  &__result {
    position: absolute;
    z-index: 6;
    width: 100%;
    box-shadow: 0 1px 3px 0px #a5aac6;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 180px;
    // & .v-list-item {
    //   border-bottom: 1px solid $neutral-color;
    // }
  }

  &__result.dark {
    background-color: $darkBgLighterDM !important;
  }

  &__result.light {
    background-color: white !important;
  }
}

.P-menu-list {
  .mdi {
    font-size: 20px;
    margin-right: 14px;
  }
}

.P-menu-list::v-deep {
  .v-list-item {
    display: block;
    min-height: unset;
    
    .list-item {
      height: 40px;
      min-height: 30px;
    }

    &:hover {
      background-color: $neutral-color15;
      cursor: pointer;
    }

    .v-list-item__title {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.theme--dark {
  .P-menu-list::v-deep {
    .v-list {
      background-color: $secondary-color-dark;
    }

    .v-list-item {
      &:hover {
        background-color: $neutralHoverDM;
        cursor: pointer;
      }
    }
  }
}
</style>