<template>
    <div class="P-order-confirmation">
        <div class="G-flex P-margin">
            <p class="P-price-group-name">Price Group Name - </p>
            <span class="P-discount">&nbsp;FIXING SOON</span>
        </div>
        <div v-if="showPackages" class="P-table">
            <div class="P-table-header" :class="$vuetify.theme.dark ? 'pt-2 pb-1' : ''">
                <ul class="G-flex G-justify-center">
                    <li v-for="(header, key) of headers" :key="key">{{header}}</li>
                </ul>
            </div>
            <template v-if="generateDisplayInfo.locationsInfo !== undefined && generateDisplayInfo.locationsInfo.locations.length">
                <template v-for="(location, index) in generateDisplayInfo.locationsInfo.locations" >
                    <div v-if="location.packages.length" :key="index">
                        <div :class="{'dark': $vuetify.theme.dark}" class="P-table-subheader">
                            <p>{{ location.locationName }} ({{ location.locationLogin }}) Room - {{ location.room }}</p>
                        </div>
                        <div class="P-table-body">
                            <ul class="P-table-body-item G-flex G-justify-center" v-for="(item, i) of location.packages" :key="i">
                                <li :class="{'white--text': $vuetify.theme.dark}">{{ getPackageName(item.packageName) }}</li>
                                <li :class="{'white--text': $vuetify.theme.dark}">{{ getActionName(location.globalAction) }}</li>
                                <li :class="{'white--text': $vuetify.theme.dark}">{{ item.startDate || '-' }}</li>
                                <li :class="{'white--text': $vuetify.theme.dark}" v-if="!generateDisplayInfo.refund">
                                    {{ item.expireNew }}
                                    <span v-if="item.expireDate !== undefined" class="end-date-was">{{ item.expireDate }}</span>
                                </li>
                                <li :class="{'white--text': $vuetify.theme.dark}" v-else>
                                  <span>Refund</span>
                                </li>
                                <li :class="{'white--text': $vuetify.theme.dark}" v-if="!generateDisplayInfo.refund">{{ location.month ? location.month+'m' : location.day+'d' }}</li>
                                <li :class="{'white--text': $vuetify.theme.dark}" v-else>-</li>
                                <li :class="{'white--text': $vuetify.theme.dark}">{{ item.currentPrice }}</li>
                                <li :class="{'white--text': $vuetify.theme.dark}">{{ item.totalPrice }}</li>
                            </ul>
                            <ul :class="{'dark': $vuetify.theme.dark}" class="total-price-block G-justify-end me-3 pb-3">
                                <span class="me-7">Total Price</span><span>{{location.totalPrice}}</span>
                            </ul>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
    import TitleComponent from "@/components/TitleComponent";
    import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
    import ConfirmationMixin from "./ConfirmationMixin";
    export default {
        name: "OrderConfirmation",
        components: {OttCheckbox, TitleComponent, OttUniversalTable},
        mixins: [ConfirmationMixin]
    }
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "./confirm";
.P-order-confirmation {
    padding-bottom: 20px;
    ul {
        &:after {
            display: none;
        }
    }
    .P-price-group-name,
    .P-discount {
        font-size: $txt14;
        font-weight: $semiBold;
    }
    .P-price-group-name {
        color: $neutral-color;
    }
    .P-discount {
        color: $danger-color-light;
    }
    .P-table {
        margin-top: 8px;
        &-header {
            font-size: $txt12;
            font-weight: $bold;
            color: $secondary-color;
        }
        &-subheader {
            background: rgb(165, 170, 198, 0.07);
            padding: 7px 18px;
            font-size: $txt12;
            font-weight: $semiBold;
            color: $secondary-color;
            margin-top: 7px;
            p {
                margin-bottom: 0;
            }
        }
        li {
            text-align: center;
            &:first-child  {
                text-align: left;
                width: 200px;
            }
            &:nth-child(2) {
                width: 150px;
            }
            &:nth-child(3) {
                width: 150px;
            }
            &:nth-child(4) {
                width: 150px;
            }
            &:nth-child(5) {
                width: 80px;
            }
            &:nth-child(6) {
                width: 150px;
            }
            &:last-child {
                width: 150px;
            }
        }
        &-body {
            &-item {
                border-bottom: 1px solid $neutral-color;;
                li {
                    font-size: $txt14;
                    color: $secondary-color;
                    padding: 16px 0;
                    .end-date-was {
                        font-size: $txt12;
                        color: $neutral-color;
                    }
                }
            }
            .total-price-block {
                &:after {
                    display: none;
                }
                align-items: center;
                font-weight: $bold;
                color: $secondary-color;
                margin: 16px 0 6px;

                & span:nth-child(1) {
                    font-size: $txt12;
                }

                & span:nth-child(2) {
                    font-size: $txt14;
                }

            }
        }
    }

}
.dark {
    color: white !important;
}

.theme--dark {
    .P-table-body ul:hover li {
        color: $white!important;
    }
}
</style>