var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ott-dialog',{attrs:{"is-open":_vm.checkoutClients.modal.isOpen,"show-footer":false,"width":1500,"show-header":false,"close-value":"addProvider"},on:{"outside":_vm.cancel,"resetDataModal":_vm.cancel},scopedSlots:_vm._u([{key:"modalBody",fn:function(){return [_c('div',{staticClass:"P-checkout-block"},[_c('CheckoutHeader',{on:{"close-modal":_vm.cancel}}),(_vm.checkoutClients.activeStep !== 4)?_c('div',{staticClass:"P-checkout-body G-flex"},[_c('div',{staticClass:"P-checkout-body-left"},[_c('div',{staticClass:"P-checkout-container"},[(
                _vm.accessShipping || _vm.invoiceData.isShipping &&
                _vm.checkoutClients.activeStep === 1
              )?_c('ShippingStep'):_vm._e(),(
                ((!_vm.accessShipping && !_vm.invoiceData.isShipping) && _vm.checkoutClients.activeStep === 1) ||
                ((_vm.accessShipping || _vm.invoiceData.isShipping) &&
                  _vm.checkoutClients.activeStep === 2)
              )?_c('PaymentStep',{ref:"paymentStep"}):_vm._e(),(
                (_vm.checkoutClients.activeStep === 2 && (!_vm.accessShipping && !_vm.invoiceData.isShipping)) ||
                _vm.checkoutClients.activeStep === 3
              )?_c('ConfirmStep'):_vm._e()],1),_c('div',{staticClass:"P-checkout-footer align-center d-flex justify-space-between"},[_c('div',{staticClass:"P-cancel-button"},[_c('OttButtonLoader',{attrs:{"is-loading":false,"button-text":'Cancel',"className":"neutral-sm-btn"},on:{"onClick":_vm.cancel}})],1),(_vm.checkoutClients.hasError)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.checkoutClients.errorMessage))]):_vm._e(),(_vm.shippingClients.hasError)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.shippingClients.errorMessage))]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(
                  _vm.accessShipping &&
                  _vm.shipFromAddressId &&
                  _vm.shipToAddressId &&
                  _vm.checkoutClients.activeStep === 1
                )?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',{staticClass:"P-shipping-steps"},[_vm._v("Shipping Steps")]),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"mdi mdi-numeric-1-circle-outline P-shipping-dot",class:{
                          'P-shipping-dot-active': _vm.shippingStep === 1,
                        },on:{"click":function($event){return _vm.changeShippingStep(1)}}},'span',attrs,false),on))]}}],null,false,1975188744)},[_c('span',[_vm._v("Shipping Addresses")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"mdi mdi-numeric-2-circle-outline P-shipping-dot",class:{
                          'P-shipping-dot-active': _vm.shippingStep === 2,
                        },on:{"click":function($event){return _vm.changeShippingStep(2)}}},'span',attrs,false),on))]}}],null,false,2595955211)},[_c('span',[_vm._v("Shipment Information")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"mdi mdi-numeric-3-circle-outline P-shipping-dot",class:{
                          'P-shipping-dot-active': _vm.shippingStep === 3,
                        },on:{"click":function($event){return _vm.changeShippingStep(3)}}},'span',attrs,false),on))]}}],null,false,1175451914)},[_c('span',[_vm._v("Choose Courier")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"mdi mdi-numeric-4-circle-outline P-shipping-dot me-0",class:{
                          'P-shipping-dot-active': _vm.shippingStep === 4,
                        },on:{"click":function($event){return _vm.changeShippingStep(4)}}},'span',attrs,false),on))]}}],null,false,2545055256)},[_c('span',[_vm._v("Handover Options & Add-ons")])])],1)]):_vm._e()])],1)]),_c('div',{staticClass:"P-checkout-body-right G-flex"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.shipFromAddress && _vm.shipToAddress)?_c('OrderDetails'):_vm._e()],1),_c('div',{class:{ 'border-top': _vm.shipFromAddress && _vm.shipToAddress }},[_c('OrderSummary')],1),_c('div',{staticClass:"mt-5"},[(_vm.paymentState === 0)?_c('ErrorMessageContainer',{attrs:{"width":100,"message":_vm.paymentErrorMessage}}):_vm._e(),(_vm.paymentState === 1)?_c('span',[_vm._v(_vm._s(_vm.paymentMessage))]):_vm._e()],1),_c('ott-button-loader',{attrs:{"is-loading":_vm.isLoading,"button-text":_vm.nextButtonLabel,"icon-after":_vm.nextButtonLabel === 'Next',"className":"primary-sm-btn P-login-btn P-next-btn"},on:{"onClick":_vm.nextOrCheckout}})],1)]):_vm._e(),(_vm.checkoutClients.activeStep === 4)?_c('div',{staticClass:"P-checkout-body G-flex"},[_c('ConfirmationSuccess')],1):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }