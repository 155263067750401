<template>
  <div class="P-add-credit-payment" :class="{ 'mt-4': $vuetify.theme.dark }">
    <v-expansion-panels v-model="showBalanceCredit" accordion class="G-accordion ">
      <v-expansion-panel>
        <v-expansion-panel-header :disable-icon-rotate="true">
          <div class="G-accordion-header G-align-center P-balance">
            <span class="icon-balance P-icon"/>
            <p class="secondary--text">Balance ${{ balance ?  balance : 0 }}</p>
<!--            <span class="P-debt-text">(Debt)</span>-->
          </div>
          <template v-slot:actions>
            <span class="mdi mdi-plus neutral--text P-accordion-plus"/>
            <span class="mdi mdi-minus neutral--text P-accordion-minus"/>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ManageBalanceCredit @closeBalanceCredit="showBalanceCredit = null" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import ManageBalanceCredit from "./ManageBalanceCredit";

  export default {
    name: 'AddCredit',
    components: {ManageBalanceCredit},
    data() {
      return {
        showBalanceCredit: null
      }
    },
    computed: {
      ...mapState({
        checkoutClients: (state) => state.checkoutClients,
        balance: (state) => state.balanceCreditClient.mainBalance,        
      })
    }
  }
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .P-balance {
    p {
      margin-bottom : 0;
      margin-left   : 25px;
      font-size     : $txt16;
      font-weight   : 600;
      margin-right  : 5px;
    }

    .P-icon {
      color     : $neutral-color;
      font-size : $txt23;
    }

    .P-debt-text {
      font-size   : $txt16;
      color       : $danger-color-light;
      font-weight : 600;
    }
  }
</style>