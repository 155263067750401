<template>
  <div>
    <div class="P-table-list">
      <div>
        <div v-if="!hasError">
          <table v-if="couriersList.length">
            <tr>
              <th class="secondary--text">Courier</th>
              <th class="secondary--text">Delivery Time</th>
              <th class="secondary--text">Tracking</th>
              <th class="secondary--text text-wrap P-description-cont">Description</th>
              <th class="secondary--text border-right-0">Total Price</th>
            </tr>

            <template v-for="(item, index) of couriersList">
              <tr :key="index" class="P-hovered-tr">
                <td class="secondary--text">
                  <v-radio-group v-model="courier">
                    <ott-radio
                        :value="item.courier_id"
                    >
                      <div class="d-flex align-center" slot="customLabel">
                        <img
                            :src="`https://assets.easyship.com/app/courier-logos/${item.courier?.logo_url}-mini.svg`"
                            class="courier-logo ms-1 me-3"
                        />

                        <span>{{ item.courier_name }}</span>
                      </div>
                    </ott-radio>
                  </v-radio-group>
                </td>

                <td class="secondary--text">
                  <p>{{ item.min_delivery_time }} <span v-if="item.max_delivery_time">- {{ item.max_delivery_time }}</span> working days</p>
                </td>

                <td class="secondary--text py-3">
                  <div
                    :class="{
                      'P-track-rating-1' : item.tracking_rating === 1,
                      'P-track-rating-2' : item.tracking_rating === 2,
                      'P-track-rating-3' : item.tracking_rating === 3,
                      'P-track-rating-4' : item.tracking_rating === 4,
                    }"
                    class="d-flex justify-center flex-nowrap P-track-rating mb-2"
                  >
                    <span class="me-2"></span>
                    <span class="me-2"></span>
                    <span class="me-2"></span>
                    <span></span>
                  </div>

                  <p>
                    {{
                      item.tracking_rating === 1 ? 'Limited' :
                      item.tracking_rating === 2 ? 'Basic' :
                      item.tracking_rating === 3 ? 'Regulyar' :
                      item.tracking_rating === 4 ? 'Excellent' :
                      ''
                    }}
                  </p>
                </td>

                <td class="secondary--text">{{ item.description }}</td>
                <td class="secondary--text border-right-0">${{ item.shipment_charge_total }}</td>
              </tr>
            </template>
          </table>

          <v-skeleton-loader
            v-else
            type="table-tbody"
          />
        </div>
<!--        <div v-if="hasError"><span>{{ errorMessage }}</span></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import {has} from "lodash";

export default {
  name: 'CouriersStep',
  components: {
    OttRadio
  },
  computed: {
    ...mapState({
      couriersList: (state) => state.shippingClients.couriersList,
      hasError: (state) => state.shippingClients.hasError,
      errorMessage: (state) => state.shippingClients.errorMessage,
      checkoutState: (state) => state.checkoutClients,
      selectedCourier: (state) => state.shippingClients.selectedCourier,
    }),

    courier: {
      set(value) {
        this.setData({ selectedCourier: value })
      },
      get() {
        return this.selectedCourier
      }
    }
  },
  methods: {
    ...mapActions({
      checkShipping: 'shippingClients/checkShipping',
      getCouriersList: 'shippingClients/getCouriersList'
    }),

    ...mapMutations({
      setData: 'shippingClients/setData'
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";

  .P-track-rating {
    & span {
      height: 22px;
      width: 9px;
      border-radius: 5px;
      background-color: #e8e5e5;
    }
  }

  .P-track-rating-1 {
    & span:nth-child(1) {
      background-color: $danger-color-light;
    }
  }

  .P-track-rating-2 {
    & span:nth-child(1), & span:nth-child(2) {
      background-color: #f7ca57;
    }
  }

  .P-track-rating-3 {
    & span:nth-child(1), & span:nth-child(2), & span:nth-child(3) {
      background-color: #1f9Aef;
    }
  }

  .P-track-rating-4 {
    & span {
      background-color: $green-new !important;
    }
  }

    .theme--light {
    & th{
      background-color  : white;  
    }
  }

  .P-hovered-tr {
    position: relative;

    &:after {
      width: 2px;
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      transition: $transition;
      background-color: $primary-color;
      content: "";
    }

    &:hover {
      &:after {
        height: 100%;
      }

      & td {
        background-color : $neutral-color7;
      }
    }
  }

  .theme--dark {
    & th {
      background-color  : $dark_light;  
    }

    .P-hovered-tr {
      &:hover {
        & td {
          background-color : transparent !important;
        }
      }
    }
  }

  table {
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    tr, td, th {
      border: 1px solid $neutral-color45;
      text-align: center;
    }

    & tr {
      th {
        font-size      : $txt14;
        font-weight    : bold;
        padding        : 8px;
        vertical-align : middle;
        text-transform : capitalize;
      }

      td {
        font-size      : $txt14;
        word-break     : break-all;
        padding        : 8px;
        transition     : $transition;
        vertical-align : middle;
        text-transform : capitalize;

        // & + li {
        //   border-left : 1px solid $neutral-color45;
        // }

        p {
          margin-bottom: 0;
        }
      }

      // border-top : 1px solid $neutral-color45;

      &:hover {
        .P-actions {
          opacity    : 1;
          visibility : visible;

        }
        td{
          background-color: $neutral-color7;
        }
      }
    }
  }

  .P-table-list {
    overflow      : hidden;
    border-radius : 4px;
    margin-top    : 12px;
    max-height    : 344px;
    overflow-y    : auto;
  }

  .P-table-body::v-deep {
    .v-input--switch {
      margin-top : 0;
    }

    .v-input__slot {
      margin-bottom : 0;
    }

    .v-messages {
      display : none;
    }

    .v-btn--icon.v-size--default {
      height: auto;
    }
  }

  .P-description-cont {
    width: 30%;
  }

  .border-right-0 {
    border-right: unset;
  }

  .courier-logo {
    width: 40px;
    border-radius: 5px;
  }
</style>

<style>
  .v-input--selection-controls {
    margin-top: unset !important;
  }
</style>