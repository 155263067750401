<template>
    <div class="P-fast-credit">
        <TitleComponent title-text="Fast Credit" />
        <v-radio-group>
            <div class="G-flex P-full-line">
                <div class="P-radio-box">
                    <OttRadio label="Day" v-model="paymentMethod.fastCreditType.day" />
                </div>
                <div class="P-radio-box">
                    <OttRadio label="Month" v-model="paymentMethod.fastCreditType.month" />
                </div>
            </div>
        </v-radio-group>
        <div class="P-padding-33 P-full-line">
            <OttInput label="Credit Term" v-model="creditTerm" />
        </div>
        <TitleComponent title-text="Bill Send" />
        <div class="G-flex P-full-line">
            <div class="P-checkbox">
                <OttCheckbox label="By Email ( Emailexample@l.com )" v-model="billSendByEmail" />
            </div>
            <div class="P-checkbox">
                <OttCheckbox label="By Postalmethod API" v-model="billSendByPostalMethod" />
            </div>
        </div>
    </div>
</template>

<script>
    import TitleComponent from "@/components/TitleComponent";
    import OttRadio from "@/components/vuetifyComponents/OttRadio";
    import OttInput from "@/components/vuetifyComponents/OttInput";
    import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
    import FastCreditCheckoutMixin from "./FastCreditCheckoutMixin"
    export default {
        name: "FastCredit",
        components: {OttCheckbox, OttInput, OttRadio, TitleComponent},
        mixins: [FastCreditCheckoutMixin]
    }
</script>

<style scoped lang="scss">
@import "src/assets/scss/formStyles";

::v-deep {
    .v-text-field__details,
    .v-messages {
        display: none;
    }
    .v-input {
        margin-top: 0;
        &__slot {
            margin-bottom: 0;
        }
    }
}
</style>