<template>
  <div class="P-check-checkout">
<!--    <title-component title-text="Cash" />-->
<!--    <div class="P-padding-20 P-dayMonth-select">-->
<!--      <ott-select-->
<!--        :value="monthValue"-->
<!--        :label="'Month'"-->
<!--        :items="monthsList"-->
<!--        :multiple="false"-->
<!--        :persistentHint="true"-->
<!--        @emitInputChange="monthValueChange"-->
<!--        selected-text="value"-->
<!--      />-->
<!--    </div>-->
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import DaysMonthMixin from "@/mixins/DaysMonthMixin";
import { mapMutations } from "vuex";

export default {
  components: {
    TitleComponent,
    OttSelect,
  },
  mixins: [DaysMonthMixin],
  data() {
    return {};
  },
  computed: {
    monthValue() {
      let dayMonthValue = 0;
      //   if (this.packagesData.locations) {
      //     this.packagesData.locations.map((item) => {
      //       if (item.locationId === this.selectedLocation.id) {
      //         dayMonthValue = item.dayMonthValue;
      //       }
      //     });
      //   }
      return dayMonthValue;
    },
  },
  methods: {
    ...mapMutations({
      setDataSecondLevels: "paymentMethodClients/setDataSecondLevels",
    }),
    async monthValueChange(monthValue) {
      this.setDataSecondLevels({ cash: { month: monthValue } });
    },
  },
};
</script>

<style lang="scss" scoped>
.P-padding-input {
  width: 50%;
}
.P-dayMonth-select {
  width: 125px;
}
</style>