<template>
  <div class="P-shipping-step">
    <title-component 
      :title-text="shippingTitle"
    >
      <!-- 
        :click="headerClickFunc"
        :is-add="shippingStep === 2 && equipmentsList.length > 1"
        for add shipping boxes
      -->
    
      <div class="d-flex align-center" slot="middle">
        <div v-if="shippingStep === 2"  class="neutral--text d-flex align-center">
          <span class="me-1">Box Dimensions</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span 
                v-bind="attrs"
                v-on="on" 
                class="mdi mdi-information-outline text-20 G-cursor-pointer">
              </span>
            </template>
            <span>Box dimensions are used to calculate the shipping cost by some couriers. Fill them in accurately to avoid potential adjustment charges later.</span>
          </v-tooltip>
        </div>

        <!-- <div class="w-max-content ms-5">
          <ott-switch v-model="returnLabel">
            <span slot="customLabel" class="neutral--text">
              Return Label
            </span>
          </ott-switch>
        </div> -->
      </div>
    </title-component>

    <!-- Shipping Steps -->
    <addresses-step v-show="shippingStep === 1" />
    <information-step v-show="shippingStep === 2" />
    <couriers-step v-show="shippingStep === 3" />
    <handover-step v-show="shippingStep === 4" />
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import TitleComponent from "@/components/TitleComponent";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import AddressesStep from "./components/AddressesStep";
import InformationStep from "./components/InformationStep";
import CouriersStep from "./components/CouriersStep";
import HandoverStep from "./components/HandoverStep";

export default {
  name: "ShippingStep",
  components: { TitleComponent, OttSwitch, AddressesStep, InformationStep, CouriersStep, HandoverStep },
  computed: {
    ...mapState({
      shippingStep: (state) => state.shippingClients.shippingStep,
      clientEquipments: (state) => state.equipments.clientEquipments,
      shipInfoBoxesData: (state) => state.shippingClients.shipInfoBoxesData,
      shippingReturnLabel: (state) => state.shippingClients.shippingReturnLabel
    }),

    returnLabel: {
      set(value) {
        this.setData({ shippingReturnLabel: value })
      },
      get() {
        return this.shippingReturnLabel
      }
    },

    equipmentsList() {
      let equipments = [];
      if (this.clientEquipments.length) {
        equipments = this.clientEquipments.filter((item) => item.equipment && !item.id);
      }

      return equipments;
    },

    shippingTitle() {
      switch (this.shippingStep) {
        case 1:
          return 'Shipping addresses';
          break;
        case 2:
          return 'Shipment Information';
          break;
        case 3:
          return 'Choose Courier';
          break;
        case 4: 
          return 'Handover Options & Add-ons'
          break;
      }
    },
  },
  created() {
    this.getCategoriesList();
  },
  methods: {
    ...mapMutations({
      addEasyShipBox: "shippingClients/addEasyShipBox",
      setData: "shippingClients/setData"
    }),

    ...mapActions({
      getCategoriesList: "shippingClients/getCategoriesList"
    }),

    /*
      To add a box need to have 2 and more pieces of equipment
      need to add boxes with maximum pieces of equipment length
    */ 
    headerClickFunc() {
      if (
        this.shippingStep === 2 &&
        this.equipmentsList.length > 1 &&
        this.shipInfoBoxesData.length < this.equipmentsList.length
      ) {
        this.addEasyShipBox()
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "src/assets/scss/variables";

  .text-20 {
    font-size: 20px;
  }
</style>
